import "swiper/css";

import { Box, Button, Image, Spinner, Text } from "grommet";
import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FreeMode, HashNavigation, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { imageThumb } from "../store/api";
import { color, margin } from "../theme";

export function Gallery(props: {
  apiListFiles: any;
  showThumbs?: boolean;
  large?: boolean;
  thumb?: boolean;
  link?: string;
  slideCount?: any;
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  function openGallery(): number | "auto" {
    return props.slideCount;
  }

  if (!props.apiListFiles) return <Spinner size="medium" color={color.main} />;
  return (
    <Box>
      <div style={{ position: "relative" }}>
        <Box>
          <Button className="gallery__prev">
            <FaArrowLeft />
          </Button>
          <Button className="gallery__next">
            <FaArrowRight />
          </Button>
        </Box>
        <ImagesLarge
          Navigation={Navigation}
          HashNavigation={HashNavigation}
          Thumbs={Thumbs}
          openGallery={openGallery}
          thumbsSwiper={thumbsSwiper}
          props={props}
        />

        {props.showThumbs && (
          <Swiper
            onSwiper={() => setThumbsSwiper}
            spaceBetween={0}
            slidesPerView={12}
            freeMode={{ enabled: true, sticky: true }}
            hashNavigation={{ replaceState: true }}
            modules={[FreeMode, Navigation, HashNavigation, Thumbs]}
            centeredSlidesBounds
          >
            {props.apiListFiles.map((item: { id: any; title: any; thumb?: any }) => {
              return (
                <SwiperSlide key={item.id} data-hash={item.id}>
                  <Box style={{ marginBottom: margin.medium }}>
                    <Box justify="center" align="center" responsive>
                      <Image fit="contain" fill="horizontal" src={imageThumb(item)} />
                    </Box>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </Box>
  );
}

function ImagesLarge({ Navigation, HashNavigation, Thumbs, openGallery, thumbsSwiper, props }) {
  return (
    <Swiper
      modules={[Navigation, HashNavigation, Thumbs]}
      hashNavigation={{
        watchState: true,
        replaceState: true,
      }}
      thumbs={{
        swiper: thumbsSwiper,
      }}
      autoHeight
      spaceBetween={0}
      slidesPerView={openGallery()}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      className="gallery"
      navigation={{
        prevEl: ".gallery__prev",
        nextEl: ".gallery__next",
      }}
    >
      {props.apiListFiles?.map((item: { id: any; description: any; thumb?: any; large?: any }) => {
        return (
          <SwiperSlide key={item.id} data-hash={item.id}>
            <Box
              style={{
                marginBottom: "20px",
              }}
            >
              <Box justify="center" align="center" responsive>
                {item.large && (
                  <Box align="center">
                    <Image fit="contain" fill="horizontal" src={item.thumb} />
                  </Box>
                )}
              </Box>
            </Box>
            <Box>
              {item.id && (
                <Link to={`${item.id}`}>
                  <Box
                    justify="center"
                    align="center"
                    className="app-overlay-area app-headline"
                    key={item.id}
                    height="medium"
                    fill
                  >
                    <Text color="brand" dangerouslySetInnerHTML={{ __html: item?.description }} />
                  </Box>
                </Link>
              )}
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
