import { Box, Button, Image, Text } from "grommet";
import { Download } from "grommet-icons";
import Plyr from "plyr-react";
import React from "react";
import { formatDateTime, isAudio, isVideo } from "store/api";

import { margin } from "../theme";

// eslint-disable-next-line react/prefer-stateless-function
class Title extends React.Component<{ item: any; avatar?: any; titleSize?: any; color?: any }> {
  render() {
    return (
      <Box margin="small" style={{ marginBottom: margin.medium }}>
        <Text size={this.props.titleSize} color={this.props.color}>
          {this.props.item}
        </Text>
        <Text size="large" color={this.props.color}>
          {this.props.item?.name}
        </Text>
        <Text size="small" color={this.props.color}>
          {this.props.item?.alternativeText}
        </Text>
      </Box>
    );
  }
}

function PlayerVideo(props: {
  dateUploaded?: any;
  dateUpdated?: any;
  filenameDisk?: any;
  autoplay?: boolean | undefined;
  canDownload?: boolean;
  muted?: boolean;
  videoControls: string[];
  title?: any;
  href?: string;
}) {
  return (
    <Box width="xlarge">
      {props.dateUploaded && (
        <Box margin="small">
          Uploaded: {formatDateTime(props.dateUploaded)}, Updated:{" "}
          {formatDateTime(props.dateUpdated)}
        </Box>
      )}
      <Plyr
        source={{
          type: "video",
          sources: [
            {
              src: `${props.filenameDisk}`,
              provider: "html5",
            },
          ],
        }}
        options={{
          autoplay: props.autoplay,
          fullscreen: { enabled: false },
          captions: { active: false },
          controls: props.videoControls,
          disableContextMenu: false,
          resetOnEnd: true,
          muted: props.muted,
        }}
      />
      {props.title && !props.canDownload && <Title titleSize="small" item={props.title} />}
      {props.title && props.canDownload && (
        <Box margin="small">
          <Button size="medium" label={`Download ${props.title}`} icon={<Download />} />
        </Box>
      )}
    </Box>
  );
}

function PlayerAudio(props: { file: any }) {
  return (
    <Box className="app-audio" width="xlarge">
      <Plyr
        source={{
          type: "audio",
          sources: [
            {
              src: `${props.file}`,
              provider: "html5",
            },
          ],
        }}
        options={{
          autoplay: false,
          fullscreen: { enabled: false },
          captions: { active: false },
          controls: ["play", "progress", "volume", "current-time", "duration"],
          disableContextMenu: false,
          resetOnEnd: true,
        }}
      />
      <Title item={props.file.title} color="accent-1" />
    </Box>
  );
}

export function AppPlayer(props: {
  media?: { type: any; filename_disk: any; title: any };
  folderFile?: any;
  mediaFile?: any;
  mediaType?: any;
  pageFile?: boolean;
  autoPlay?: boolean;
  autoLoad?: boolean;
  muted?: boolean;
  poster?: any;
  title?: any;
  station?: any;
}) {
  function getVideoControls() {
    return ["play-large", "play", "progress", "volume", "fullscreen", "current-time", "duration"];
  }

  return (
    <Box>
      {props.mediaFile && (
        <Box width="xlarge">
          <Box>
            {isVideo(props.mediaType) && (
              <PlayerVideo
                filenameDisk={props.mediaFile}
                autoplay={props.autoPlay}
                videoControls={getVideoControls()}
                title={props.mediaFile.title}
                muted={props.muted}
              />
            )}
            {isAudio(props.mediaType) && <PlayerAudio file={props.mediaFile} />}
          </Box>
        </Box>
      )}
    </Box>
  );
}

interface Props {
  mediaBackground?: any;
  mediaType?: any;
  pageFile?: boolean;
  autoPlay?: boolean;
  autoLoad?: boolean;
  muted?: boolean;
  image?: any;
}

export function MediaPlayerBackground({ mediaBackground, mediaType, image }: Props) {
  return (
    <>
      {mediaBackground && (
        <div style={{ position: "absolute", width: "100%", height: "100%", objectFit: "cover" }}>
          <video muted loop autoPlay width="100%" height="100%" style={{ width: "100%" }}>
            <source src={mediaBackground} type={mediaType} />
          </video>
        </div>
      )}
      {image && (
        <Box
          height={"large"}
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "cover",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              content: "",
              display: "block",
              background: "rgba(0,0,0,0.5)",
            }}
          ></div>
          <Image src={image} width="100%" height="auto" fit="cover" />
        </Box>
      )}
    </>
  );
}
