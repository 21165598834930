import { useHistory } from "react-router-dom";
import PageProps from "types/page/PageProps";

import site from "./Site";

export const isSearchPage = window.location.pathname === "/en/search/result";

export async function getAllUsers() {
  const response = await fetch("/api/user/me");
  return await response.json();
}

export async function getHeader() {
  const response = await fetch("/api/page/en");
  return await response.json();
}

export async function createUser(data) {
  const response = await fetch(`/api/user`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ user: data }),
  });
  return await response.json();
}

export async function apiSite() {
  const response = await fetch("/api/site/en");
  return await response.json();
}

export async function apiNav(path, language) {
  const response = await fetch(`/api/nav/${language}/${path}`);
  return await response.json();
}

export async function fetchErrorPage() {
  const response = await fetch("/api/page/en");
  return await response.json();
}

export async function apiPage(url) {
  const response = await fetch(`/api/page${url}`);
  return await response.json();
}

export async function apiSearch(url) {
  const response = await fetch(`/api/search/result${url}`);
  return await response.json();
}

export async function apiList(url) {
  const response = await fetch(`/api/list/en/10000/${url}`);
  return await response.json();
}

export async function apiListCompanies(url) {
  const response = await fetch(`/api/list/en/10000/${url}`);
  return await response.json();
}

export async function apiPageCount(template, name) {
  const response = await fetch(`/api/page/count/${template}/${name}`);
  return await response.json();
}

export async function apiForm(url) {
  const response = await fetch(`/api/form/${url}`);
  return await response.json();
}

export async function apiParentPage(url) {
  const response = await fetch(`/api/parent${url}`);
  return await response.json();
}

export async function fetchProducts() {
  const response = await fetch("/api/products/en");
  return await response.json();
}

export async function fetchSlides() {
  const response = await fetch("/api/page/en");
  return await response.json();
}

export async function postImport(id) {
  return await fetch(site.domain + "/api/import?token=", {
    method: "post",
    headers: { "Content-Type": "application/json", "Cockpit-Token": site.token },
    body: JSON.stringify({
      filter: { published: true, title: id },
      body: "",
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      // console.log(res.entries);
      return res.entries;
    });
}

export async function UserLogout() {
  const history = useHistory();
  try {
    const response = await fetch("/api/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      history.push("/");
    } else {
      throw new Error("Logout failed");
    }
  } catch (error) {
    console.error("Logout error:", error);
  }
}
