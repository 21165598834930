import {
  Accordion,
  AccordionPanel,
  Box,
  Grid,
  Grommet,
  Heading,
  Image,
  Page,
  Spinner,
  Text,
} from "grommet";
import { apiList, isSearchPage } from "helpers/Fetch";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import PageProps from "types/page/PageProps";

import {
  color,
  gridCoutries,
  gridOneColumn,
  gridThreeColumns,
  gridTwoColumns,
  margin,
  responsiveSize,
  theme,
} from "../theme";
import { AppPlayer, MediaPlayerBackground } from "./AppPlayer";
import BlocksOffers from "./blocks/BlocksOffers";
import { CompanyGrid } from "./CompanyGrid";
import { BoatDetailsTop } from "./content/BoatDetails";
import ContentBoat from "./ContentBoat";
import FormSearch, { SearchFormInfo } from "./form/FormSearch";
import { FormRequest } from "./FormRequest";
import { Gallery } from "./Gallery";
import List from "./List";
import { SearchResult } from "./SearchResult";
import { User } from "./User";
import ViewPort from "./ViewPort";

interface Props {
  page?: any;
  searchData?: any;
  allPages?: any;
  list?: any;
  site?: any;
  slides?: any;
}

export default function Content({ page, site, searchData }: Props) {
  const [flexibilities, setFlexibilities] = useState(null);
  const [sailingArea, setSailingArea] = useState(null);
  const [yachtTypes, setYachtTypes] = useState(null);
  const [countries, setCountries] = useState(null);
  const [uniqueStartBases, setUniqueStartBases] = useState(null);
  const [bookingType, setBookingType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const fetchList = async (endpoint: string, setter: Function) => {
        try {
          const response = await apiList(endpoint);
          setter(response);
        } catch (error) {
          console.error(`Error fetching ${endpoint}: ${error}`);
        }
      };

      if (isSearchPage || page?.name === "prodeck") {
        await Promise.all([
          fetchList("flexibility", setFlexibilities),
          fetchList("sailingareas", setSailingArea),
          fetchList("yachttypes", setYachtTypes),
          fetchList("countries", setCountries),
          fetchList("bases", setUniqueStartBases),
          fetchList("booking_type", setBookingType),
        ]);
      }
    };

    fetchData();
  }, []);
  return (
    <Grommet theme={theme(site.color?.value, site.color_accent?.value)}>
      <Helmet>
        <title>{page?.metatitle}</title>
        <meta id="meta-description" name="description" content={page?.description} />
        <meta id="og-title" property="og:title" content={page?.metatitle} />
      </Helmet>

      <Page margin={{ bottom: "large" }}>
        <ContentArea page={page} site={site} searchData={searchData} />

        {page.template === "page" && page?.images && page?.images.length > 1 && (
          <Box margin="auto">
            <Gallery
              apiListFiles={page?.images}
              slideCount={responsiveSize(["xsmall", "small"]) ? 1 : 4}
            />
          </Box>
        )}

        {page?.repeater && page?.repeater.length > 0 && <Components page={page} site={site} />}
      </Page>
    </Grommet>
  );
}

function ContentArea({ page, site, searchData }: Props) {
  const [flexibilities, setFlexibilities] = useState(null);
  const [sailingArea, setSailingArea] = useState(null);
  const [yachtTypes, setYachtTypes] = useState(null);
  const [countries, setCountries] = useState(null);
  const [uniqueStartBases, setUniqueStartBases] = useState(null);
  const [bookingType, setBookingType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const fetchList = async (endpoint: string, setter: Function) => {
        try {
          const response = await apiList(endpoint);
          setter(response);
        } catch (error) {
          console.error(`Error fetching ${endpoint}: ${error}`);
        }
      };

      if (isSearchPage || page?.name === "prodeck") {
        try {
          await Promise.all([
            fetchList("flexibility", setFlexibilities),
            fetchList("sailingareas", setSailingArea),
            fetchList("yachttypes", setYachtTypes),
            fetchList("countries", setCountries),
            fetchList("bases", setUniqueStartBases),
            fetchList("booking_type", setBookingType),
          ]);
        } catch (error) {
          console.error(`Error fetching data: ${error}`);
        }
      }
    };

    fetchData();
  }, []);
  return (
    <Box>
      <Box
        background={page.template === "boat" ? color.dark : undefined}
        width={"100%"}
        height={isSearchPage ? "small" : page.template === "boat" ? "medium" : "large"}
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          width={"100%"}
          style={{
            position: "absolute",
            objectFit: "cover",
            zIndex: 0,
          }}
        >
          {page.images && <MediaPlayerBackground image={page.images[0].large} />}
          {page.media && page.checkbox2?.value === 1 && (
            <MediaPlayerBackground
              mediaBackground={page.media[0].url}
              mediaType={page.media.type}
            />
          )}
        </Box>

        <Box flex align="center" justify="center" pad={{ top: "large" }}>
          <Box
            width="xlarge"
            animation="fadeIn"
            style={{
              position: "relative",
              zIndex: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {page.template === "boat" && <BoatTitle page={page} site={site} />}

            {page.template !== "boat" && (
              <Heading color={"light-1"} level="1">
                {page.title?.value}
              </Heading>
            )}

            {page.date?.value && (
              <Box>
                <Text color={"light-1"}>{page.date.label}</Text>
                <Text color={"light-1"}>
                  <Moment unix date={page.date?.value} format="DD. MMM yyyy" />
                </Text>
              </Box>
            )}

            {page.name === "prodeck" && (
              <Box
                background={"light-1"}
                margin={{ top: "medium" }}
                width={"100%"}
                pad={"small"}
                style={{ borderRadius: margin.small }}
              >
                <FormSearch
                  flexibilities={flexibilities}
                  sailing_area={sailingArea}
                  booking_type={bookingType}
                  yachtTypes={yachtTypes}
                  countries={countries}
                  bases={uniqueStartBases}
                  site={site}
                />
                <SearchFormInfo site={site} />
              </Box>
            )}

            <Box pad={responsiveSize(["small", "medium"]) ? "small" : ""}>
              <Text
                size="large"
                color={color.white}
                dangerouslySetInnerHTML={{
                  __html: page.body?.value,
                }}
              />
            </Box>

            {page.media &&
              page.checkbox2?.value !== 1 &&
              page.media.map((media, index) => (
                <Box
                  key={index}
                  margin={{
                    bottom: "medium",
                  }}
                >
                  <AppPlayer mediaFile={media.url} mediaType={media.type} />
                  {media.description && <Text color={"light-1"}>{media.description}</Text>}
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      {page.name === "account" && page.userRole === "manager" && <CompanyGrid site={site} />}
      {page.template === "boat" && <ContentBoat site={site} single={page} />}
      {isSearchPage && (
        <SearchResult
          yachtTypes={yachtTypes}
          sailing_area={sailingArea}
          flexibilities={flexibilities}
          booking_type={bookingType}
          countries={countries}
          filteredData={searchData}
          site={site}
        />
      )}
    </Box>
  );
}

function Components({ page, site, columns }: PageProps) {
  return (
    <Box>
      {page.repeater?.map((item) => {
        if (item.content_type.value === "Accordion") {
          return (
            <Box key={item.id}>
              <ViewPort>
                <ContentAccordion page={item} id={item.content_type?.value} />
              </ViewPort>
            </Box>
          );
        }
        if (item.content_type.value === "Content") {
          return (
            <Box key={item.id}>
              <ViewPort>
                <ContentGrid page={item} id={item.content_type?.value} />
              </ViewPort>
              <ContentForm id={item.content_type?.value} item={item} page={page} site={site} />
            </Box>
          );
        }
        if (item.content_type.value === "Banner") {
          return (
            <Box key={item.id}>
              <ViewPort>
                <ContentBanner item={item} columns={gridOneColumn()} BlockColors={BlockColors} />
              </ViewPort>
              <ContentForm id={item.content_type?.value} item={item} page={page} site={site} />
            </Box>
          );
        }
        if (item.content_type.value === "Offers") {
          return (
            <Box key={item.id}>
              <ViewPort>
                {item.form?.map((formItem, index) => (
                  <BlocksOffers
                    key={index}
                    countries={formItem.search_countries}
                    kind={formItem.search_kind}
                    title={formItem.title}
                    site={site}
                  />
                ))}
              </ViewPort>
              <ContentForm id={item.content_type?.value} item={item} page={page} site={site} />
            </Box>
          );
        }
        if (item.content_type.value === "2 columns") {
          return (
            <Box key={item.id}>
              <ViewPort>
                <ContentGrid page={item} id={item.content_type?.value} />
              </ViewPort>
              <ContentForm id={item.content_type?.value} item={item} page={page} site={site} />
            </Box>
          );
        }
        if (item.content_type.value === "3 columns") {
          return (
            <Box key={item.id}>
              <ViewPort>
                <ContentGrid page={item} id={item.content_type?.value} />
              </ViewPort>
              <ContentForm item={item} page={page} site={site} id={item.content_type?.value} />
            </Box>
          );
        }
        if (item.content_type.value === "5 columns") {
          return (
            <Box key={item.id}>
              <ViewPort>
                <ContentGrid page={item} id={item.content_type?.value} />
              </ViewPort>
              <ContentForm id={item.content_type?.value} item={item} page={page} site={site} />
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
}

export function ContentAccordion({ page, id }) {
  return (
    <Accordion width={"xlarge"} id={id}>
      {page && (
        <AccordionPanel
          className="font-bold"
          label={
            <Heading size="medium" level="3">
              {page.title?.value}
            </Heading>
          }
        >
          {page.body && (
            <Box>
              <Text
                dangerouslySetInnerHTML={{
                  __html: page.body?.value,
                }}
              />
            </Box>
          )}
        </AccordionPanel>
      )}
    </Accordion>
  );
}

export function ContentGrid({ page, id }) {
  const isMoreThanOneImage = page.images && page.images.length > 1;
  const isOneImage = page.images && page.images.length === 1;
  const type = page.content_type?.value;

  const typeRows = type === "Rows";
  const typeBanner = type === "Banner";
  const typeColumns = type === "2 columns";
  const typeThreeColumns = type === "3 columns";
  const typeFiveColumns = type === "5 columns";

  return (
    <Box
      id={id}
      background={BlockColors(page)}
      pad={{
        top: BlockColors(page) ? "large" : "none",
        bottom: BlockColors(page) ? "large" : "none",
      }}
    >
      <Box flex alignSelf="center" width={"100%"} responsive>
        <ContentLoading isLoading={page} />
        <Grid
          columns={ItemsType(page)}
          style={typeBanner ? { position: "relative" } : undefined}
          alignSelf="center"
        >
          {typeBanner ||
            (isOneImage && page.position.value === "Left" && (
              <OverlayImage page={page} typeBanner={typeBanner} />
            ))}
          <Box
            flex
            style={typeBanner ? { position: "absolute" } : undefined}
            justify={typeBanner ? "center" : undefined}
            width={"100%"}
            pad={"medium"}
            height={typeBanner ? "100%" : undefined}
            align={typeBanner ? "center" : undefined}
          >
            <Box
              flex
              justify={"center"}
              align={"center"}
              width={"xxlarge"}
              pad={"medium"}
              height={typeBanner ? "100%" : undefined}
            >
              <Heading
                level="2"
                alignSelf="center"
                textAlign="center"
                color={BlockColors(page) ? color.white : color.main}
                margin={"none"}
              >
                {page.title?.value}
              </Heading>
              <Text
                color={BlockColors(page) ? color.white : color.black}
                size={"large"}
                dangerouslySetInnerHTML={{
                  __html: page.body?.value,
                }}
              />
            </Box>
          </Box>
          {isOneImage && page.position?.value === "Right" && (
            <OverlayImage page={page} typeBanner={typeBanner} />
          )}
          {isMoreThanOneImage && (
            <Box flex direction="row" width={"100%"}>
              {page.images.map((image, index) => (
                <Box key={index} pad="small">
                  <Image src={image.portrait} width={"100%"} height={"auto"} />
                </Box>
              ))}
            </Box>
          )}
        </Grid>
        <Box align="center">
          <ContentCards id={type} card={page.cards} columns={ItemsType(page)} />
        </Box>
      </Box>
    </Box>
  );
}

export function ContentCards({ card, columns, id }) {
  return (
    <Grid
      id={id}
      columns={columns}
      margin={columns ? "auto" : "none"}
      style={{ borderRadius: margin.small, overflow: "hidden" }}
    >
      {card &&
        card.map((item, index) => (
          <Box
            id={item.content_type?.value}
            direction={columns ? "column" : "row"}
            key={index}
            className="font-bold"
            background={BlockColors(item)}
          >
            {item.content_type?.value === "Accordion" && (
              <Box flex align="center" width={"xlarge"}>
                <ContentAccordion id={item.content_type?.value} page={item} />
              </Box>
            )}
            {item.content_type?.value === "Banner" && (
              <ContentBanner
                id={item.content_type?.value}
                columns={columns}
                BlockColors={BlockColors}
                item={item}
              />
            )}
            {item.content_type?.value === "Content" && (
              <ContentRegular
                id={item.content_type?.value}
                columns={columns}
                BlockColors={BlockColors}
                item={item}
              />
            )}
            {item.content_type?.value === "2 columns" && (
              <ContentRegular
                id={item.content_type?.value}
                columns={columns}
                BlockColors={BlockColors}
                item={item}
              />
            )}
            {item.content_type?.value === "3 columns" && (
              <ContentRegular
                id={item.content_type?.value}
                columns={columns}
                BlockColors={BlockColors}
                item={item}
              />
            )}
            {item.content_type?.value === "5 columns" && (
              <ContentRegular
                id={item.content_type?.value}
                columns={columns}
                BlockColors={BlockColors}
                item={item}
              />
            )}
          </Box>
        ))}
    </Grid>
  );
}

function ContentLoading({ isLoading }) {
  if (!isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size={"large"} color={color.main} />
      </div>
    );
  }

  return null;
}

function OverlayImage({ page, typeBanner }) {
  if (page?.images) {
    return (
      <Box>
        {typeBanner && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              content: "",
              display: "block",
              background: "rgba(0,0,0,0.5)",
            }}
          />
        )}

        {page.images.map((image, index) => (
          <Box key={index}>
            <Image src={typeBanner ? image.large : image.square} width={"100%"} height={"auto"} />
          </Box>
        ))}
      </Box>
    );
  }

  return null;
}

function ContentRegular({ columns, BlockColors, item, id }) {
  return (
    <>
      <Box id={id} width={columns ? "100%" : "large"}>
        {item.image && item.image.length > 0 && (
          <Image src={item.image[0]?.square} width={"100%"} height={"auto"} />
        )}
      </Box>
      <Box
        width={columns ? "100%" : "large"}
        pad={columns ? "small" : "medium"}
        background={BlockColors(item)}
      >
        <Heading
          size="large"
          level="3"
          color={color.main}
          margin={{
            top: "none",
            bottom: "none",
          }}
        >
          {item.title?.value}
        </Heading>
        {item.body && (
          <Box>
            <Text
              size="large"
              dangerouslySetInnerHTML={{
                __html: item.body?.value,
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

function ContentBanner({ columns, BlockColors, item, id }: PageProps) {
  console.log(item);

  return (
    <Box
      id={id}
      width={columns ? "100%" : "large"}
      height={columns ? "medium" : "medium"}
      style={{ position: "relative" }}
    >
      <OverlayImage page={item} typeBanner={"Banner"} />
      <Box
        width={columns ? "100%" : "large"}
        background={BlockColors(item)}
        flex
        justify="center"
        style={{ position: "absolute" }}
        pad={"medium"}
        height={"100%"}
        align={"center"}
      >
        <Heading
          size="large"
          level="3"
          color={color.white}
          margin={{
            top: "none",
            bottom: "none",
          }}
        >
          {item.title?.value}
        </Heading>
        {item.body && (
          <Box color={color.white}>
            <Text
              dangerouslySetInnerHTML={{
                __html: item.body?.value,
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

function ContentForm({ item, page, site, id }) {
  return (
    <Grommet id={id} theme={theme(site.color?.value, site.color_accent?.value)}>
      {item.form.map((form) => (
        <Box key={form.id}>
          {form.select.value === "List" && (
            <List title={form.title?.value} list={form.textfield?.value} />
          )}
          {form.select.value === "Login" && <User site={site} />}
          {form.select.value === "Booking" && (
            <FormRequest formCollection={"booking"} parent={page.name} />
          )}
        </Box>
      ))}
    </Grommet>
  );
}

export function BlockColors(page) {
  if (page.block_background?.value === "gray") {
    return color.accent;
  }
  if (page.block_background?.value === "secondary") {
    return color.secondary;
  }
  if (page.block_background?.value === "dark") {
    return color.dark;
  }
  if (page.block_background?.value === "main") {
    return color.main;
  }
  if (page.block_background?.value === "white") {
    return color.white;
  }
}
export function ItemsType(page) {
  if (["Rows", "Offers"].includes(page.content_type?.value)) {
    return gridOneColumn();
  }
  if (page.content_type?.value === "Banner") {
    return gridOneColumn();
  }
  if (page.content_type?.value === "2 columns") {
    return gridTwoColumns();
  }
  if (page.content_type?.value === "3 columns") {
    return gridThreeColumns();
  }
  if (page.content_type?.value === "5 columns") {
    return gridCoutries();
  }
  if (page.content_type?.value === "Table") {
    return "table";
  }
}

function BoatTitle({ page, site }) {
  return (
    <Box flex direction="column" align="center" justify="center">
      <Text size="3xl" margin={{ bottom: "small" }}>
        {page?.model?.value} | {page?.title?.value}
      </Text>
      {page.userRole === "manager" && (
        <a
          target="_blank"
          href={`https://portal.booking-manager.com/wbm2/page.html?view=YachtDetails&templateType=responsive&companyid=${page.companyId}&yachtId=${page.yachtId}&addMargins=true`}
          rel="noreferrer"
        >
          Show in Booking Manager
        </a>
      )}

      <BoatDetailsTop single={page} site={site} />
    </Box>
  );
}
