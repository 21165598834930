import { Box, Button, Form, FormField, Notification, Text, TextInput } from "grommet";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageProps from "types/page/PageProps";

import { CompanyGrid } from "./CompanyGrid";

export function User({ site }: PageProps) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const history = useHistory();

  const handleUser = async (event) => {
    event.preventDefault();

    const loginData = {
      username,
      pass: password,
    };

    try {
      const response = await fetch("/api/user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        // Handle successful login
        const userData = await response.json();
        setLoggedInUsername(userData.username);
      } else {
        // Handle login error
      }
    } catch (error) {
      // Handle network or fetch error
    }
    history.push("/");
  };

  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const response = await fetch(`/api/user/me`);
        if (response.ok) {
          const userData = await response.json();
          setLoggedInUsername(userData.name);
        }
      } catch (error) {
        // Handle network or fetch error
      }
    };

    if (loggedInUsername !== "guest") {
      fetchLoggedInUser();
    }
  }, [loggedInUsername]);

  if (loggedInUsername === "guest") {
    return (
      <UserGuest
        handleUser={handleUser}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
      />
    );
  }
  if (site.userRole !== "guest") {
    return (
      <Box>
        <UserProfile loggedInUsername={loggedInUsername} site={site} />
        <CompanyGrid site={site} />
      </Box>
    );
  }

  return null; // Return null for other user roles
}

function UserGuest({ handleUser, username, setUsername, password, setPassword }) {
  return (
    <Box align="center" pad="medium">
      <Form onSubmit={handleUser}>
        <FormField name="username" label="Username">
          <TextInput value={username} onChange={(e) => setUsername(e.target.value)} />
        </FormField>
        <FormField name="password" label="Password">
          <TextInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormField>
        <Button type="submit" primary label="Login" />
      </Form>
    </Box>
  );
}

function UserProfile({ loggedInUsername, site }: PageProps) {
  const [username, setUsername] = useState("");
  const [pass, setPassword] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState("");

  const handleResetPassword = (e) => {
    e.preventDefault();

    // Add your password reset logic here
    fetch(`/api/user/password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        pass,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setResetSuccess(true);
          setResetError("");
        } else {
          throw new Error("Password reset failed");
        }
      })
      .catch((error) => {
        setResetSuccess(false);
        setResetError(error.message);
      });
  };
  return (
    <Box>
      <Box margin="small">
        {site.userRole !== "guest" && <Text>You are logged in as {loggedInUsername}.</Text>}
      </Box>

      {site.userRole === "guest" && (
        <Box>
          <Form onSubmit={handleResetPassword}>
            <FormField name="username" label="Username">
              <TextInput value={username} onChange={(e) => setUsername(e.target.value)} />
            </FormField>
            <FormField name="pass" label="Confirm Password">
              <TextInput
                type="password"
                value={pass}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormField>
            <Button type="submit" secondary label="Reset Password" />
          </Form>
          {resetSuccess && <Notification toast title="Password reset successful!" />}
          {resetError && <Notification toast title={resetError} />}
        </Box>
      )}
    </Box>
  );
}
