import {
  Box,
  Button,
  FileInput,
  FormField,
  Grid,
  Image,
  Layer,
  Menu,
  Spinner,
  Text,
  TextInput,
} from "grommet";
import { Calendar, User } from "grommet-icons";
import { apiNav, UserLogout } from "helpers/Fetch";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import PageProps from "types/page/PageProps";

import { imageThumbSingle } from "../store/api";
import { gridTwoColumns, margin } from "../theme";
import AppAlert from "./AppAlert";

interface TextFieldProps {
  value: string;
  setOpen: (open: boolean) => void;
  setValue: (value: string) => void;
}

function TextField(props: TextFieldProps) {
  const { value, setValue, setOpen } = props;

  const handleBlur = async () => {
    try {
      await fetch("/api/users/me/update", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ first_name: value }),
      });
      setOpen(true);
    } catch (error) {
      console.error("Error updating first name:", error);
    }
  };

  return (
    <FormField label="First name">
      <TextInput
        type="text"
        name={value}
        value={value}
        placeholder="Username"
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleBlur}
      />
    </FormField>
  );
}

function MyProfileAvatar(props: { avatar: string; avatarSize?: string }) {
  return (
    <Image
      className="border-circle"
      src={imageThumbSingle(props.avatar)}
      fit="cover"
      alignSelf="center"
      width={props.avatarSize}
      height={props.avatarSize}
      alt=""
    />
  );
}

export default function MyProfile() {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [password, setNewPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  // @ts-ignore
  const { user } = "";

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setRole(user.role);
      setAvatar(user.avatar);
      setFirstName(user.first_name);
      setLastName(user.last_name);
    }
  }, [user]);

  async function updateAvatar() {
    const fileInput = document.querySelector('input[type="file"]');
    const formData = new FormData();
    if (!fileInput) {
      return; // early return if fileInput is not found
    }
    // @ts-ignore
    formData.append("file", fileInput.files[0]);
    const deleteCurrent = await fetch(`/api/files/${avatar}`, {
      method: "DELETE",
    });
    const addNew = await fetch("/api/files", {
      method: "POST",
      body: formData,
    });
    // @ts-ignore
    const updateNew = directus.users.me.update({ avatar: addNew.id });
    await Promise.all([deleteCurrent, updateNew]);
    setOpen(true);
  }

  return (
    <Box>
      <AppAlert show={open} />
      <Grid columns={gridTwoColumns()}>
        {user && (
          <Grid>
            {user.avatar && (
              <Box justify="center" margin="auto" width="small">
                <MyProfileAvatar avatar={user.avatar} avatarSize="120" />
              </Box>
            )}
            {user.last_access && (
              <Box justify="center" align="center" direction="row">
                <Text margin="small">
                  <Calendar />
                </Text>
                <Moment date={user.last_access} format="HH:mm | DD. MMM yyyy" />
              </Box>
            )}
            <FormField label="Avatar" name="file-input" htmlFor="file-input">
              <FileInput
                name="file-input"
                id="file-input"
                type="file"
                // @ts-ignore
                onChange={async (event, { files }) => {
                  return await updateAvatar();
                }}
                confirmRemove={({ onConfirm, onCancel }) => (
                  <Layer onClickOutside={onCancel} onEsc={onCancel}>
                    <Box pad="medium" gap="medium">
                      Are you sure you want to delete this file?
                      <Box direction="row" align="center" justify="end" gap="small">
                        <Button label="Cancel" onClick={onCancel} />
                        <Button label="Delete file" onClick={onConfirm} primary />
                      </Box>
                    </Box>
                  </Layer>
                )}
              />
            </FormField>
            <TextField value={first_name} setValue={setFirstName} setOpen={setOpen} />
            <FormField label="Last name">
              <TextInput
                type="text"
                name="last_name"
                value={last_name}
                placeholder="Last name"
                onChange={(e) => setLastName(e.target.value)}
                // @ts-ignore
                onBlur={async (e) => {
                  try {
                    await fetch("/api/users/me/update", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({ last_name }),
                    });
                    setOpen(true);
                  } catch (error) {
                    console.error("Error updating last name:", error);
                  }
                }}
              />
            </FormField>
            <FormField label="Email">
              <TextInput
                type="email"
                name="email"
                value={email}
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
                // @ts-ignore
                onBlur={async (e) => {
                  try {
                    await fetch("/api/users/me/update", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({ email }),
                    });
                    setOpen(true);
                  } catch (error) {
                    console.error("Error updating email:", error);
                  }
                }}
              />
            </FormField>
            <FormField label="New password">
              <TextInput
                type="password"
                name="password"
                value={password}
                placeholder="Add new password"
                autoComplete="off"
                // @ts-ignore
                onChange={(e) => setNewPassword(e.target.value)}
                // @ts-ignore
                onBlur={async (e) => {
                  try {
                    await fetch("/api/users/me/update", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({ password }),
                    });
                    setOpen(true);
                  } catch (error) {
                    console.error("Error updating password:", error);
                  }
                }}
              />
            </FormField>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export function MyAvatar({ color, site }: PageProps) {
  const [loading, setLoading] = useState(true);
  const [userItems, setUserItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = await apiNav("user", "en");

        const items = userProfile.map((item) => ({
          label: item.menu_title?.value,
          href: item.url,
          onClick: item.onClick,
        }));
        setUserItems(items);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    UserLogout();
  };

  if (loading) {
    // You can return a loading state or a default avatar while data is being fetched
    return <Spinner size="medium" color={color} />;
  }

  return (
    <Box
      flex
      justify="evenly"
      direction="row"
      gap="small"
      background={"black"}
      style={{ borderRadius: margin.small }}
    >
      <Box flex justify="evenly" align="center" direction="row">
        <Menu
          label={<User color={color} />}
          dropBackground={{ color: "white" }}
          items={userItems}
        />
      </Box>
    </Box>
  );
}
