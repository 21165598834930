import { Spinner } from "grommet";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { color } from "theme";

import Header from "./components/Header";
import Page from "./components/Page";
import { apiNav, apiSite } from "./helpers/Fetch";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [site, setSiteSettings] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const fetchedSiteSettings = await apiSite();
      const getHeader = await apiNav("header", "en");

      setIsLoading(false);
      setSiteSettings(fetchedSiteSettings);
      setPages(getHeader);
    };
    fetch().catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size={"large"} color={color.main} />
      </div>
    );
  }

  return (
    <Router>
      <Header header={pages} site={site} />
      <Switch>
        <Route
          exact
          path="**"
          render={() => <Page data={pages} path={location.pathname} header={pages} site={site} />}
        />
      </Switch>
    </Router>
  );
}
