import { Box, Button, ResponsiveContext } from "grommet";
import { Search } from "grommet-icons";
import React, { useContext, useState } from "react";
import { margin } from "theme";
interface Props {
  desktop: any;
  mobile: any;
  site: any;
}

export default function ShowMobile({ desktop, mobile, site }: Props) {
  const [show, setShow] = useState(false);

  const size = useContext(ResponsiveContext);

  const isMobile = size === "small" || size === "xsmall";

  return (
    <Box pad={{ left: "small", right: "small", bottom: "small" }} animation={"fadeIn"}>
      <Box
        background={!isMobile || show ? site?.color_background : "transparent"}
        align="center"
        style={{ borderRadius: margin.small }}
      >
        {isMobile && (
          <Box width={"large"}>
            <Button
              size="large"
              primary
              color={show ? site.color_background : site.color}
              label={show ? "Close search" : "Open search"}
              icon={<Search />}
              onClick={() => setShow(!show)}
            />
          </Box>
        )}
        <Box>
          {show && (
            <Box pad={{ left: "small", right: "small", bottom: "medium" }} width={"large"}>
              {mobile}
            </Box>
          )}
        </Box>
        <Box>{!isMobile && <Box>{desktop}</Box>}</Box>
      </Box>
    </Box>
  );
}
