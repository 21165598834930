function domainName() {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    return "http://prodeck.ddev.site";
  } else {
    return "";
  }
}

const site = {
  token: "d27a83310274e7e7a8e465b48cb9e6",
  domain: domainName(),
  hasSlider: false,
};

export default site;
