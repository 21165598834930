import { Box, FormField, Notification, RadioButton, Text, TextArea, TextInput } from "grommet";
import moment from "moment";
import React, { useEffect } from "react";
import { apiDomain, bookingManagerToken, bookingManagerUrl } from "store/api";

export const anonymousToken = "xTAlqD0Wlw6V65B2XkIAd2aqvE2giNRg";
export const anonymousId = "ad941c9b-3866-4c61-a875-361486c7f53f";
export const adminToken = "lW56CuRpYt_aQ4W8yequuL81su0fVZyc";
export const publicToken = "wnjYiJDS9Ddyi6EXxk4-TDVczGYRbN1j";
export const mapBoxToken =
  "pk.eyJ1Ijoic2lpbGFrIiwiYSI6ImNsa3JqamFweDFrYm0zam9jYW0yZnlvYzQifQ.-afQnSoNzUEup9Q_j-YRSQ";

export const bigDataToken = "bdc_ec2bd8ef60564cc5aefb9cd855677d6d";

export function getEndTime(time) {
  // @ts-ignore
  const startTime = moment(time, "HH:mm");
  const endTime = startTime.add(1.5, "hours");
  return endTime.format("HH:mm");
}

export function apiListCollection(setGrid) {
  useEffect(() => {
    const fetchGrid = async () => {
      const response = await fetch("/api/booking");
      const data = await response.json();
      setGrid(data);
    };
    fetchGrid();
  }, []);
}

export function apiCollection(list, site, setData) {
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `/api/${list}?site=${site.id}&sort=-date&offset=0&fields=id,image,counter,url,translations.title,translations.body`
      );
      const data = await response.json();
      setData(data);
    }

    fetchData();
  }, []);
}

export function apiActivity(setData) {
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`/api/activity?sort=-id`);
      const data = await response.json();
      setData(data);
    }

    fetchData();
  }, []);
}

export const FormRadioButton = ({ id, label, checked, onChange }) => (
  <Box direction="column" gap="small" pad="small">
    <Text>{label}</Text>
    <RadioButton name={id} id={id} label={label} checked={checked} onChange={onChange} />
  </Box>
); // Custom textarea component
export const FormTextArea = ({ id, value, onChange, label }) => (
  <FormField label={label} htmlFor={id}>
    <TextArea id={id} value={value} onChange={onChange} />
  </FormField>
); // Custom input component
export const FormTextInput = ({ id, value, onChange, label, type = "text", required = false }) => (
  <FormField label={label} htmlFor={id}>
    <TextInput id={id} type={type} value={value} onChange={onChange} required={required} />
  </FormField>
);

export interface Props {
  imagePaths?: any;
  id?: number;
  yachtId?: number;
  list?: any;
  yachtType?: any;
  site?: any;
  item?: any;
  title?: string;
  name?: string;
  baseFromId?: number;
  baseToId?: number;
  optionExpirationDate?: string;
  date_updated?: string;
  shortName?: string;
  model?: string;
  modelId?: number;
  startBaseId?: number;
  endBaseId?: number;
  startBase?: string;
  endBase?: string;
  dateFrom?: string;
  dateTo?: string;
  status?: number;
  product?: string;
  price?: number;
  startPrice?: number;
  obligatoryExtrasPrice?: number;
  securityDeposit?: number;
  commissionValue?: number;
  discountPercentage?: number;
  country?: string;
  city?: string;
  shipyardId?: number;
  year?: number;
  kind?: string;
  homeBaseId?: number;
  companyId?: number;
  company?: string;
  draught?: number;
  beam?: number;
  homeBase?: string;
  length?: number;
  waterCapacity?: number;
  fuelCapacity?: number;
  engine?: string;
  deposit?: number;
  depositWithWaiver?: number;
  currency?: string;
  commissionPercentage?: number;
  maxDiscountFromCommissionPercentage?: number;
  wc?: number;
  berths?: number;
  cabins?: number;
  wcNote?: string;
  berthsNote?: string;
  cabinsNote?: string;
  mainsailType?: string;
  genoaType?: string;
  requiredSkipperLicense?: number;
  defaultCheckInDay?: number;
  allCheckInDays?: number[];
  defaultCheckInTime?: string;
  defaultCheckOutTime?: string;
  minimumCharterDuration?: number;
  images?: any;
  coordinates?: any;
  equipmentIds?: string[];
  equipment?: object[];
  products?: any[];
}

export async function apiBookingManager(collectionName: string) {
  const response = await fetch(bookingManagerUrl + "/" + collectionName, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bookingManagerToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  const data = await response.json();
  return data;
}

export async function apiFetchLocal(collectionName: string) {
  const response = await fetch(apiDomain + "/" + collectionName + "?limit=100000", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${publicToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  const data = await response.json();
  return data.data;
}

export function getId(item: any): number {
  return Number(String(item));
}

let orderNumber = 0;
export function getDummyId(): number {
  orderNumber++;
  return orderNumber;
}

export function formatTimeStart() {
  return "T00:00:00.00";
}

export function formatTimeEnd() {
  return "T00:00:00.00";
}

export function OffersDate() {
  const moment = require("moment");

  const today = moment();
  const currentDayOfWeek = today.day();
  const daysUntilThisSaturday = 6 - currentDayOfWeek;
  const daysUntilNextSaturday = 13 - currentDayOfWeek;

  const dateStart = moment(today).add(daysUntilThisSaturday, "days").startOf("day");
  const dateEnd = moment(today).add(daysUntilNextSaturday, "days").startOf("day");

  const numberOfDays = dateEnd.diff(dateStart, "days");

  const localStartDate = getLocalStartDate();
  const localEndDate = getLocalEndDate();
  const localDays = getLocalDays();

  const encodedDateFrom = encodeURIComponent(dateStart.format("YYYY-MM-DD")) + formatTimeStart();
  const encodedDateTo = encodeURIComponent(dateEnd.format("YYYY-MM-DD")) + formatTimeEnd();

  return {
    encodedDateFrom,
    encodedDateTo,
    localStartDate,
    localEndDate,
    localDays,
  };

  function getLocalEndDate() {
    if (typeof window !== "undefined") {
      if (!localStorage.getItem("date_end")) {
        localStorage.setItem("date_end", dateEnd.format("YYYY-MM-DD") + formatTimeStart());
      }
    }
  }

  function getLocalStartDate() {
    if (typeof window !== "undefined") {
      if (!localStorage.getItem("date_start")) {
        localStorage.setItem("date_start", dateStart.format("YYYY-MM-DD") + formatTimeEnd());
      }
    }
  }

  function getLocalDays() {
    if (typeof window !== "undefined") {
      if (!localStorage.getItem("numberOfDays")) {
        localStorage.setItem("numberOfDays", numberOfDays);
      }
    }
  }
}

export function OffersDateCurrentYear() {
  const moment = require("moment");
  require("moment-timezone");

  const today = moment().tz("Europe/London").startOf("isoWeek");
  return today.format("YYYY");
}

export function getAllDatesBeforeToday() {
  const moment = require("moment");
  const startOfMonth = moment().startOf("month").toISOString();
  const yesterday = moment().subtract(1, "day").toISOString();
  return [[startOfMonth, yesterday]];
}

export async function deleteAllItems(collection: string) {
  try {
    const res = await fetch(`/items/${collection}?limit=100000`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${adminToken}`,
        "Content-Type": "application/json",
      },
    });
    const page = await res.json();
    const post = page.data;
    const itemIds = post.map((item) => item.id);

    for (const itemId of itemIds) {
      await fetch(`/items/${collection}/${itemId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${adminToken}`,
          "Content-Type": "application/json",
        },
      });
    }

    console.log(`Deleted ${itemIds.length} items from ${collection}`);
  } catch (error) {
    console.error(error);
  }
}

export async function companyStatus(collection: string, companyId: string, statusMessage: number) {
  try {
    const response = await fetch(`/api/field/put/en/status/${companyId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: statusMessage }),
    });
    if (!response.ok) {
      throw new Error("Failed to patch items");
    }
  } catch (error) {
    console.error(error);
    return (
      <Notification
        status="critical"
        message={`Failed to patch ${companyId} items in ${collection}`}
      />
    );
  }

  return <Notification status="info" message={`Patched ${companyId} items in ${collection}`} />;
}
