import { Box, ResponsiveContext } from "grommet";
import React, { useContext, useEffect, useState } from "react";
interface Props {
  desktop?: any;
  mobile?: any;
  regularTop?: boolean;
  site?: any;
}

export default function ShowScroll({ desktop, mobile, site, regularTop }: Props) {
  const [show, setShow] = useState(false);

  const size = useContext(ResponsiveContext);

  const isMobile = size === "small" || size === "xsmall";

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 90) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box style={{ position: "relative" }}>
      <Box
        margin={{
          top: !isFixed && !isMobile && !regularTop ? "-50px" : isMobile ? "15px" : "0px",
        }}
        animation={"fadeIn"}
        width={!isMobile && isFixed ? "medium" : "100%"}
        style={{
          position: isFixed ? "fixed" : "relative",
          top: !isMobile && isFixed ? "0px" : "0px",
          zIndex: isFixed ? "1" : "0",
        }}
        pad={{ right: !isMobile && isFixed ? "medium" : "0px" }}
      >
        <Box>{desktop}</Box>
      </Box>
    </Box>
  );
}
