import { Box } from "grommet";
import React from "react";
import { color } from "theme";
import PageProps from "types/page/PageProps";

import Navigation from "./Navigation";
const Header = ({ header: allPages, site }: PageProps) => {
  return (
    <Box
      width={"100%"}
      align="center"
      background={location.pathname.includes("search") ? color.dark : "transparent"}
      animation={{ type: "fadeIn", duration: 800 }}
      style={{ position: "absolute", zIndex: 1 }}
    >
      <Navigation header={allPages} site={site} path={`/${site.language}`} fontSize="medium" />
    </Box>
  );
};
export default Header;
