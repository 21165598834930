import { Box, Grid, Grommet, List, Text } from "grommet";
import { Location, Schedule } from "grommet-icons";
import iso from "iso-3166-1-alpha-2";
import { FaBed, FaWater } from "react-icons/fa";
import Flag from "react-world-flags";

import { gridResponsive, gridTwoColumns, margin, theme } from "../../theme";
import {
  IconBeam,
  IconCabins,
  IconDraught,
  IconEngine,
  IconFuel,
  IconLength,
  IconMainsail,
  IconToilet,
  IconType,
} from "./Icons";

interface BoatDetailsProps {
  site: {
    color: any;
    color_accent: any;
  };
  single?: any;
}

export function BoatTitle(props: { color: string; title: string }) {
  return (
    <Text
      size="2xl"
      color={props.color}
      style={{ textTransform: "uppercase", display: "block", marginTop: margin.small }}
    >
      {props.title}
    </Text>
  );
}

export function BoatDetails({ single, site }: BoatDetailsProps) {
  if (typeof window !== "undefined") {
    localStorage.setItem("berths", JSON.stringify(single?.berths?.value));
  }

  const boxData = [
    {
      label: "Boat year",
      icon: <IconMainsail color={site.color?.value} />,
      fontSize: "medium",
      value: single?.year?.value,
    },
    {
      label: "Cabins",
      icon: <IconCabins color={site.color?.value} />,
      fontSize: "medium",
      value: single?.cabins?.value + " " + single?.cabinsNote?.value + "",
    },
    {
      label: "Berths",
      icon: <FaBed color={site.color?.value} />,
      fontSize: "medium",
      value: single?.berths?.value + " " + single?.berthsNote?.value + "",
    },
    {
      label: "Toilets",
      icon: <IconToilet color={site.color?.value} />,
      fontSize: "medium",
      value: single?.wc?.value + " " + single?.wcNote?.value + "",
    },
    {
      label: "Type",
      icon: <IconType color={site.color?.value} />,
      fontSize: "medium",
      value: single?.kind?.value,
    },
    {
      label: "Length",
      icon: <IconLength color={site.color?.value} />,
      fontSize: "medium",
      value: single?.length?.value,
    },
    {
      label: "Beam",
      icon: <IconBeam color={site.color?.value} />,
      fontSize: "medium",
      value: single?.beam?.value,
    },
    {
      label: "Draught",
      icon: <IconDraught color={site.color?.value} />,
      fontSize: "medium",
      value: single?.draught?.value,
    },
    {
      label: "Type of mainsail",
      icon: <IconMainsail color={site.color?.value} />,
      fontSize: "medium",
      value: single?.mainsailType?.value,
    },
    {
      label: "Type of genoa",
      icon: <IconMainsail color={site.color?.value} />,
      fontSize: "medium",
      value: single?.genoaType?.value,
    },
    {
      label: "Engine",
      icon: <IconEngine color={site.color?.value} />,
      fontSize: "medium",
      value: single?.engine?.value,
    },
    {
      label: "Fuel capacity",
      icon: <IconFuel color={site.color?.value} />,
      fontSize: "medium",
      value: single?.fuelCapacity?.value,
    },
    {
      label: "Home base",
      icon: <Location color={site.color?.value} />,
      fontSize: "medium",
      value: single?.homeBase?.value,
    },
    {
      label: "Water capacity",
      icon: <FaWater color={site.color?.value} />,
      fontSize: "medium",
      value: single?.waterCapacity?.value,
    },
  ];

  return (
    <div>
      <BoatTitle color={site.color?.value} title="Boat details" />
      <Grid columns={gridTwoColumns()}>
        <List
          data={boxData.slice(0, 7)}
          margin={{ top: "small", bottom: "small" }}
          primaryKey="label"
          secondaryKey="value"
        >
          {(datum) => (
            <Box height={"18px"}>
              <Box direction="row" justify="start" align="center">
                <Text color={"accent"} style={{ marginRight: margin.small }}>
                  {datum.icon}
                </Text>
                <Text size={datum.fontSize}>{datum.label}:</Text>
                <Text size={datum.fontSize} margin={{ left: "small" }}>
                  {datum.value}
                </Text>
              </Box>
            </Box>
          )}
        </List>
        <List
          data={boxData.slice(7)}
          margin={{ top: "small", bottom: "small" }}
          primaryKey="label"
          secondaryKey="value"
        >
          {(datum) => (
            <Box height={"18px"}>
              <Box direction="row" justify="start" align="center">
                <Text color={"accent"} style={{ marginRight: margin.small }}>
                  {datum.icon}
                </Text>
                <Text size={datum.fontSize}>{datum.label}:</Text>
                <Text size={datum.fontSize} margin={{ left: "small" }}>
                  {datum.value}
                </Text>
              </Box>
            </Box>
          )}
        </List>
      </Grid>
    </div>
  );
}

export function BoatDetailsTop({ single, site }: BoatDetailsProps) {
  const boxData = [
    {
      label: single?.kind?.label,
      icon: <IconType size={23} color={"white"} />,
      fontSize: "medium",
      value: single?.kind?.value,
    },
    {
      label: single?.homeBase?.label,
      icon: <Location />,
      fontSize: "medium",
      value: single?.homeBase?.value,
      flag: String(iso.getCode(single?.country?.value)),
    },
    {
      label: single?.defaultCheckInTime?.label,
      icon: <Schedule />,
      fontSize: "medium",
      value: single?.defaultCheckInTime?.value,
    },
    {
      label: single?.defaultCheckOutTime?.label,
      icon: <Schedule />,
      fontSize: "medium",
      value: single?.defaultCheckOutTime?.value,
    },
  ];

  return (
    <Grid
      gap="small"
      width={"xlarge"}
      align="center"
      justify="start"
      columns={gridResponsive()}
      margin={{ top: "small", bottom: "small" }}
    >
      {boxData.map(({ label, icon, flag, value, fontSize }, index) => (
        <Box direction="row" justify="start" align="center" key={index}>
          <Text color={site.color_accent?.value} style={{ marginRight: margin.small }}>
            {icon}
          </Text>
          <Text margin={{ right: "xsmall" }} size={fontSize}>
            {label}:
          </Text>
          {flag && <Flag code={flag} height="30" width="30" />}
          <Text size={fontSize} margin={{ left: "xsmall" }}>
            {value}
          </Text>
        </Box>
      ))}
    </Grid>
  );
}
