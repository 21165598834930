import { Box, FormField, Select, Text } from "grommet";
import { ChangeEvent, useState } from "react";
export function FormSelect(props: {
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  options?: (item: any) => { label: any; value: any };
  formData?: any;
  isForm?: boolean;
  disabled?: any;
  selectData?: any;
  name?: string;
  type?: string;
  label?: any;
  isAmount?: boolean;
  required?: boolean;
  showSearch?: boolean;
}) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(undefined);

  const searchOptions = Array.isArray(props.selectData)
    ? props.selectData.filter(
        (country) =>
          country.label && country.label.toLowerCase().includes(searchValue.toLowerCase())
      )
    : [];

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    if (!event.target.value) {
      setSearchValue("");
    }
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleSelectClose = () => {
    setSearchValue("");
  };

  const handleSearchChange = (searchText) => {
    if (searchText !== "") {
      setSearchValue(searchText);
    }
  };

  return (
    <Box width={"100%"} pad={"xsmall"}>
      {props.isForm && (
        <FormField label={props.label}>
          <Select
            name={props.name}
            onChange={handleSelectChange}
            type={props.type}
            required={props.required}
            options={searchOptions?.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            value={props.formData}
            labelKey="label"
            valueKey={{ key: "value", reduce: true }}
            searchPlaceholder="Search countries..."
            disabled={props.disabled}
            onSearch={props.showSearch ? handleSearchChange : undefined}
            onClose={handleSelectClose}
            defaultChecked={selectedValue === undefined}
            dropHeight="medium"
          />
        </FormField>
      )}
      {props.isAmount && <Text>{props.selectData?.length}</Text>}
    </Box>
  );
}

export function FormSelectPeople(props: {
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options?: (item: any) => { label: any; value: any };
  formData?: any;
  isForm?: boolean;
  disabled?: any;
  selectData?: any;
  name?: string;
  type?: string;
  label?: any;
  isAmount?: boolean;
  required?: boolean;
  onSearch?: (searchText: any) => any;
}) {
  return (
    <FormField label={props.label}>
      {props.isForm && (
        <Select
          name={props.name}
          onChange={props.onChange}
          type={props.type}
          required={props.required}
          options={getAllValuesBefore()}
          value={props.formData}
          labelKey="label"
          valueKey={{ key: "value", reduce: true }}
          onSearch={props.onSearch}
          searchPlaceholder="Search countries..."
          disabled={props.disabled}
        />
      )}
      {props.isAmount && <Text>{props.selectData?.length}</Text>}
    </FormField>
  );

  function getAllValuesBefore(): (string | number | boolean | object | JSX.Element)[] {
    if (props.name === "how_many_people") {
      const setMax = localStorage.getItem("berths");
      const maxNumber = setMax ? parseInt(setMax) : 0;
      const numbers = Array.from({ length: maxNumber }, (_, index) => (index + 1).toString());
      return numbers.map((item: any) => {
        const label = item > 1 ? item + " persons" : item + " person";
        return {
          label,
          value: item,
        };
      });
    }
    return [...props.selectData.map(props.options)];
  }
}
