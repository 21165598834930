import { TextInput } from "grommet";
import { adminToken } from "plugins/directus";
import React, { useEffect, useState } from "react";
interface MetaInfo {
  required: boolean;
  options: {
    url: string;
  };
}

interface AutocompleteData {
  name: string;
}

export function FormRequestAutocomplete({
  name,
  type,
  meta,
}: {
  name: string;
  type: string;
  meta: MetaInfo;
}) {
  const [autoComplete, setAutoComplete] = useState<AutocompleteData[]>([]);
  const [formValue, setFormValue] = useState("");

  const fetchAutoCompleteFromAPI = async (apiUrl: string) => {
    const response = await fetch(apiUrl + formValue, {
      headers: {
        Authorization: `${adminToken}`,
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    return json.data;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setFormValue(event.target.value);
  };

  useEffect(() => {
    fetchAutoCompleteFromAPI(meta.options.url)
      .then((value) => {
        setAutoComplete(value);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
      });
  }, [formValue, meta.options.url]);

  const suggestions = autoComplete
    ? autoComplete.map((data) => ({
        label: data.name,
        value: data.name,
      }))
    : [];
  const onSelect = (event) => setFormValue(event.suggestion.label);
  return (
    <TextInput
      name={name}
      type={type}
      required={meta.required}
      value={formValue || ""}
      onChange={handleInputChange}
      suggestions={suggestions}
      onSuggestionSelect={onSelect}
      style={{
        border: 0,
      }}
    />
  );
}
