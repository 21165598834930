import { Box, Button, Grid, Spinner, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { gridSearch, gridThreeColumns } from "theme";

import { BoatListItem } from "./blocks/BlocksOffers";
import { IconMainsail } from "./content/Icons";
import FormSearch from "./form/FormSearch";
import MapboxGLComponent from "./map/MapboxGLComponent";
import ShowMobile from "./ShowMobile";
import ShowScroll from "./ShowScroll";

interface Props {
  filteredData?: any;
  yachtTypes?: any;
  flexibilities?: any;
  sailing_area?: any;
  booking_type?: any;
  handleFormSubmit?: any;
  countries?: any;
  site?: any;
  showMap?: any;
}

export function SearchResult({
  filteredData,
  yachtTypes,
  flexibilities,
  sailing_area,
  booking_type,
  handleFormSubmit,
  countries,
  site,
  showMap,
}: Props) {
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [sortByPriceAsc, setSortByPriceAsc] = useState(true); // Initial sorting order

  const [itemsToShow, setItemsToShow] = useState(18);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [defaultMinPrice, setDefaultMinPrice] = useState(0);
  const [defaultMaxPrice, setDefaultMaxPrice] = useState(0);
  const itemsToLoad = 18;

  useEffect(() => {
    setTimeout(() => {
      setShowNotFoundMessage(true);
    }, 100);
  }, []);

  const searchResult = Array.isArray(filteredData)
    ? filteredData
        .filter((item) => item.price >= minPrice && item.price <= maxPrice)
        .sort((a, b) => {
          if (sortByPriceAsc) {
            return a.price - b.price;
          } else {
            return b.price - a.price;
          }
        })
    : [];
  const lowestPrice = searchResult.length > 0 ? searchResult[0]?.price : 0;
  const highestPrice = searchResult.length > 0 ? searchResult[searchResult.length - 1]?.price : 0;

  // Updated useEffect
  useEffect(() => {
    if (lowestPrice && highestPrice) {
      setDefaultMinPrice(lowestPrice);
      setDefaultMaxPrice(highestPrice);
    }
  }, [lowestPrice, highestPrice]);

  const uniqueStartBases = [];
  const uniqueStartBaseIds = new Set();

  const startBases = [];
  if (Array.isArray(searchResult) && searchResult.length > 0) {
    const uniqueStartBaseIds = new Set();
    searchResult.forEach((item) => {
      if (!uniqueStartBaseIds.has(item.startBaseId)) {
        // @ts-ignore
        uniqueStartBases.push({ label: item.startBase, value: item.startBaseId });
        uniqueStartBaseIds.add(item.startBaseId);
      }
    });
  }

  const togglePriceSorting = () => {
    setSortByPriceAsc((prev) => !prev);
  };

  const visibleItems = searchResult?.slice(0, itemsToShow);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      itemsToShow < searchResult.length
    ) {
      setItemsToShow(itemsToShow + itemsToLoad);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [itemsToShow, searchResult]);

  return (
    <Grid columns={gridSearch()} className="boat-area" style={{ position: "relative" }}>
      <Box>
        <ShowScroll
          desktop={
            <Box
              margin={{ top: "medium" }}
              style={{
                transition: "position 0.3s ease-in-out",
              }}
            >
              {showMap && (
                <Box style={{ zIndex: 1 }}>
                  <MapboxGLComponent />
                </Box>
              )}
              <ShowMobile
                site={site}
                desktop={
                  <FormSearch
                    flexibilities={flexibilities}
                    sailing_area={sailing_area}
                    booking_type={booking_type}
                    yachtTypes={yachtTypes}
                    onSubmit={handleFormSubmit}
                    countries={countries}
                    bases={uniqueStartBases}
                    site={site}
                    isFull
                  />
                }
                mobile={
                  <FormSearch
                    flexibilities={flexibilities}
                    sailing_area={sailing_area}
                    booking_type={booking_type}
                    yachtTypes={yachtTypes}
                    onSubmit={handleFormSubmit}
                    countries={countries}
                    bases={uniqueStartBases}
                    site={site}
                    isFull
                  />
                }
              />
            </Box>
          }
        />
      </Box>
      <Box>
        <Box>
          <Box
            direction="row"
            justify="between"
            align="center"
            pad={{ left: "small", right: "small" }}
          >
            <Box direction="row" justify="start" align="center">
              <Text color={site.color_accent?.value}>
                {filteredData?.length ? `Found ${filteredData.length}` : "Searching boats..."}
              </Text>
              <IconMainsail color={site.color_accent?.value} size={32} />
            </Box>
            <Box direction="row" justify="end" align="center">
              <Button
                onClick={togglePriceSorting}
                primary
                color={site.color_accent?.value}
                icon={sortByPriceAsc ? <FaArrowCircleDown /> : <FaArrowCircleUp />}
                label={"Sort by Price"}
              />
            </Box>
          </Box>
          {searchResult.length > 0 ? (
            <Grid columns={gridThreeColumns()}>
              {visibleItems.map((item, index) => (
                <Box key={index}>
                  <BoatListItem item={item} language={site.language} />
                </Box>
              ))}
            </Grid>
          ) : showNotFoundMessage ? (
            <Box height="medium" justify="center" align="center" animation="fadeIn">
              <Spinner size="large" color={site.color?.value} />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Grid>
  );
}
