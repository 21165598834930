import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Box, Button, Grid, Heading, Image, Spinner, Text, Tip } from "grommet";
import { Location } from "grommet-icons";
import iso from "iso-3166-1-alpha-2";
import { OffersDate, Props } from "plugins/directus";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { color, gridTwoColumns, Hidden, margin, responsiveSize, screenSm } from "theme";
import PageProps from "types/page/PageProps";
interface IconProps {
  icon: any;
  name: any;
}

export function BoatIcon({ icon, name }: IconProps) {
  return (
    <Box flex direction="row" align="center">
      {icon}
      <Text margin={{ left: "xsmall" }} size="small">
        {name}
      </Text>
    </Box>
  );
}

export default function BlocksOffers({ countries, kind, title, site }: PageProps) {
  const [data, setData] = useState([]);

  const { encodedDateFrom, encodedDateTo } = OffersDate();

  useEffect(() => {
    const getOffers = async () => {
      try {
        const url = `/api/search/list?dateFrom=${encodedDateFrom}&dateTo=${encodedDateTo}&country=${
          countries.shortName
        }${kind?.title ? `&kind=${kind?.title}` : ""}`;
        const response = await fetch(url, { method: "GET" });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setData(data);
      } catch (error) {
        console.error(error);
      }
    };

    getOffers();
  }, []);

  const buttonStyle = {
    color: color.main,
    background: color.white,
    padding: margin.small,
    display: "flex",
    justifyContent: "center",
    borderRadius: "50%",
  };

  const breakpoints = {
    460: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    768: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1400: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  };
  const width = data.length === 3 ? "xlarge" : "xxlarge";

  const prevButtonId = `${countries?.shortName}-prev`;
  const nextButtonId = `${countries?.shortName}-next`;

  return (
    <Box margin="auto" width={width}>
      <Box pad="small" align="center">
        {title && (
          <Heading level="2" color={site.color?.value} alignSelf="center" textAlign="center">
            {title?.value}
          </Heading>
        )}
      </Box>

      {!data.length && (
        <Box height="medium">
          <Box flex justify="center" align="center">
            <Spinner size="medium" color={site.color?.value} />
          </Box>
        </Box>
      )}

      <div style={{ width: "100%", position: "relative" }}>
        <Hidden width={screenSm}>
          <Box>
            <Button id={prevButtonId} className="gallery__prev" style={buttonStyle}>
              <FaArrowLeft />
            </Button>
            <Button id={nextButtonId} className="gallery__next" style={buttonStyle}>
              <FaArrowRight />
            </Button>
          </Box>
        </Hidden>

        <Swiper
          id={countries?.shortName}
          autoHeight
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          modules={[Navigation, Pagination, FreeMode]}
          freeMode={{ enabled: true, sticky: true }}
          touchRatio={1}
          pagination={{
            clickable: true,
          }}
          centeredSlidesBounds
          scrollbar={{ draggable: true }}
          breakpoints={breakpoints}
          navigation={{
            prevEl: `#${prevButtonId}`,
            nextEl: `#${nextButtonId}`,
          }}
        >
          {data.slice(0, 12).map((item: any) => (
            <SwiperSlide key={item.id}>
              <BoatListItem item={item} language={site.language} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Box>
  );

  function countSlides() {
    if (responsiveSize(["medium"])) {
      return 3;
    }
    if (responsiveSize(["small"])) {
      return 2;
    }
    if (responsiveSize(["xsmall"])) {
      return 1;
    }
    return 4;
  }
}

interface BoatData {
  images?: any;
  model: any;
  name: any;
  url?: any;
  yacht: any;
  company: any;
  year: any;
  product?: any;
  country?: any;
  berths: any;
  length: any;
  cabins: any;
}

export function BoatListItem({ item, language }: PageProps) {
  const [boat, setBoat] = useState<BoatData | undefined>(undefined);

  useEffect(() => {
    const getBoat = async () => {
      try {
        const response = await fetch(`/api/boat/teaser/${language}/${item.yachtId}`);
        const data = await response.json();

        setBoat(data);
      } catch (error) {
        console.error(error);
      }
    };

    getBoat();
  }, [item.id]);
  const [loading, setLoading] = useState(true);

  const discountPercentage = Math.floor(((item.startPrice - item.price) / item.startPrice) * 100);

  const handleLoadingComplete = () => {
    setLoading(false);
  };
  const sendBoatData = () => {
    // Remove existing localStorage items
    localStorage.removeItem("date_start");
    localStorage.removeItem("date_end");
    localStorage.removeItem("price");
    localStorage.removeItem("securityDeposit");
    localStorage.removeItem("discount");
    localStorage.removeItem("productName");

    // Add new localStorage items
    if (item.dateFrom) {
      localStorage.setItem("date_start", item.dateFrom);
    }
    if (item.dateTo) {
      localStorage.setItem("date_end", item.dateTo);
    }
    if (item.price) {
      localStorage.setItem("price", item.price);
    }
    if (item.product) {
      localStorage.setItem("productName", item.product);
    }
    if (item.securityDeposit) {
      localStorage.setItem("securityDeposit", item.securityDeposit);
    }
    if (item.discountPercentage) {
      localStorage.setItem("discount", String(discountPercentage));
    }
  };

  const isDiscount = item.startPrice !== item.price;
  const isStartPrice = item.startPrice;
  const isPrice = item.price;
  const getFlag = boat?.country ? String(iso.getCode(boat.country)) : "";

  const isRegularPrice = Math.floor(item.startPrice);
  return (
    <Box
      background="light-1"
      round="small"
      pad="xsmall"
      margin={{ bottom: "medium" }}
      style={{ position: "relative" }}
    >
      <Link to={`/${boat?.url}`} onClick={sendBoatData}>
        <Box style={{ position: "relative" }}>
          {isDiscount && isStartPrice && (
            <Box
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              background={color.yellow}
              pad="xsmall"
              margin={{ top: "xsmall", right: "xsmall" }}
              flex
              justify="center"
              align="center"
            >
              <Text color={color.black} className="font-bold">
                {"-" + discountPercentage + "%"}
              </Text>
            </Box>
          )}

          <Box
            width="100%"
            overflow="hidden"
            flex
            justify="center"
            align="center"
            style={{ minHeight: "200px" }}
          >
            {boat?.images && (
              <Image
                src={boat.images}
                alt={boat?.model?.value}
                width="440"
                height="200"
                loading="lazy"
                style={{
                  objectFit: "cover",
                  maxHeight: "200px",
                }}
              />
            )}
          </Box>

          <Box style={{ position: "relative" }}>
            <Box style={{ position: "absolute", right: "0", top: "5px" }}>
              <Tip
                content={
                  <Box pad="xsmall" background={color.white} style={{ borderRadius: margin.small }}>
                    {item.startBase}
                  </Box>
                }
                dropProps={{ align: { bottom: "top" } }}
                plain
              >
                <Location />
              </Tip>
            </Box>

            <Box margin={{ top: "xsmall", bottom: "xsmall" }}>
              <Text color={color.black} size="large">
                <Text weight={"bold"} size="large">
                  {boat?.model?.value}
                </Text>
                <Text margin={{ left: "xsmall" }} size="large" truncate>
                  {item?.yacht}
                </Text>
              </Text>
              {isPrice && (
                <Box direction="row" align="center" justify="start">
                  <Box margin={{ right: "small" }}>
                    <Text color={color.black}>
                      <Moment date={item.dateFrom} format="DD. MM YYYY" />
                    </Text>
                  </Box>
                  <FaArrowRight size="12" color={color.black} />
                  <Box margin={{ left: "small" }}>
                    <Text color={color.black}>
                      <Moment date={item.dateTo} format="DD. MM YYYY" />
                    </Text>
                  </Box>
                </Box>
              )}
              <Box flex direction="row" align="center">
                <Box width="24px">
                  <Flag code={getFlag} />
                </Box>

                <Box>
                  {item.startBase && (
                    <Box flex direction="row" justify="start" margin={{ left: "small" }}>
                      <Text
                        size="small"
                        color={color.black}
                        margin={{ right: "xsmall" }}
                        weight={"bold"}
                      >
                        {item.startBase === item.endBase && "Start & End base"}
                        {item.startBase !== item.endBase && "Start base"}
                      </Text>
                      <Box>
                        <Text size="small" color={color.black}>{`${item.startBase}`}</Text>
                      </Box>
                    </Box>
                  )}
                  {item.startBase !== item.endBase && (
                    <Box flex direction="row" align="center" margin={{ left: "small" }}>
                      <Text
                        size="small"
                        weight={"bold"}
                        color={color.black}
                        margin={{ right: "xsmall" }}
                      >
                        End base
                      </Text>
                      <Text
                        size="small"
                        weight={"bold"}
                        color={color.black}
                      >{`${item.endBase}`}</Text>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            <Grid columns={gridTwoColumns()}>
              {boat?.year !== 0 && (
                <Box>
                  <Text color={color.black}>
                    <Text weight={"bold"}>{boat?.year?.label}:</Text> {`${boat?.year?.value}`}
                  </Text>
                </Box>
              )}

              {boat?.berths !== 0 && (
                <Box>
                  <Text color={color.black}>
                    <Text weight={"bold"}>{boat?.berths?.label}:</Text> {`${boat?.berths?.value}`}
                  </Text>
                </Box>
              )}

              {boat?.length !== 0 && (
                <Box>
                  <Text color={color.black}>
                    <Text weight={"bold"}>{boat?.length?.label}:</Text> {`${boat?.length?.value}`}
                  </Text>
                </Box>
              )}

              {boat?.cabins !== 0 && (
                <Box>
                  <Text color={color.black}>
                    <Text weight={"bold"}>{boat?.cabins?.label}:</Text> {`${boat?.cabins?.value}`}
                  </Text>
                </Box>
              )}
            </Grid>

            {item.product && (
              <Box>
                <Text color={color.black}>
                  <Text weight={"bold"}>Service:</Text> {`${item.product}`}
                </Text>
              </Box>
            )}

            {item.price && (
              <Box margin={{ top: "small", bottom: "medium" }} flex direction="row" align="center">
                <Text
                  size="medium"
                  color={color.secondary}
                  margin={{ right: "small" }}
                >{`${item.price} ${item?.currency}`}</Text>
                {item.price && item.price !== isRegularPrice && (
                  <Text color={color.black} style={{ textDecoration: "line-through" }}>
                    {isDiscount ? `${isRegularPrice} ${item?.currency}` : ""}
                  </Text>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
