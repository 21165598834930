import { BoatGallery } from "components/BoatGallery";
import { BoatAvailability } from "components/content/BoatAvailability";
import { BoatDetails, BoatDetailsTop, BoatTitle } from "components/content/BoatDetails";
import { BoatExtra } from "components/content/BoatExtra";
import ShowMobile from "components/ShowMobile";
import ShowScroll from "components/ShowScroll";
import { Box, Grid, Text } from "grommet";
import React from "react";
import { color, gridSearch, Hidden, screenXs, Visible } from "theme";
import PageProps from "types/page/PageProps";

import { BookingCalendar } from "./BookingCalendar";

function BoatEquipment({ eqIds, equipment }) {
  console.log(equipment);

  return (
    <Box>
      {equipment && <BoatTitle color={color.main} title={"Equipment"} />}
      <Box flex justify="evenly" align="start" direction="row">
        {Array.isArray(eqIds) &&
          Object.entries(
            equipment.data
              .filter(({ id, category }) => eqIds.includes(id) && category.translations[0]?.title)
              .reduce((grouped, { id, name, category }) => {
                const title = category.translations[0]?.title;
                if (!grouped[title]) {
                  grouped[title] = [];
                }
                grouped[title].push({ id, name });
                return grouped;
              }, {} as Record<string, { id: string; name: string }[]>)
          ).map(([title, group]: [string, { id: string; name: string }[]]) => (
            <React.Fragment key={title}>
              <Box margin={{ bottom: "small" }} width={"100%"}>
                <Text size="medium" color={color.main} className={"font-bold"}>
                  {title}
                </Text>
                {group.map(({ id, name }) => (
                  <Text key={id} size="large">
                    {name}
                  </Text>
                ))}
              </Box>
            </React.Fragment>
          ))}
      </Box>
    </Box>
  );
}

function PageBoatsContent({
  single,
  site,
  equipment,
  yachtTypes,
  block_form,
  flexibilities,
  countries,
}) {
  // const eqIds = single.equipmentIds?.value.map((item: any) => item);

  return (
    <Grid columns={gridSearch()} id="boat-area">
      <Hidden width={screenXs}>
        <Box>
          {/* <ShowScroll
            regularTop
            desktop={
              <ShowMobile
                site={site}
                desktop={
                  <BookingCalendar
                    single={single}
                    site={site}
                    countries={countries}
                    yachtTypes={yachtTypes}
                    flexibilities={flexibilities}
                    block_form={block_form}
                    showObligatoryExtras
                  />
                }
                mobile={
                  <BookingCalendar
                    single={single}
                    site={site}
                    countries={countries}
                    yachtTypes={yachtTypes}
                    flexibilities={flexibilities}
                    block_form={block_form}
                    showObligatoryExtras
                  />
                }
              />
            }
          /> */}
        </Box>
      </Hidden>

      <Box>
        {/* <BoatGallery images={single.images} site={site} showThumbs /> */}
        <Box margin={{ top: "medium", bottom: "medium" }}>
          {/* <BoatAvailability site={site} id={single.yachtId} country={single.country} /> */}
        </Box>
        <BoatDetails single={single} site={site} />
        <Box margin={{ top: "small", bottom: "small" }}>
          {/* <BoatEquipment equipment={equipment} eqIds={eqIds} /> */}
        </Box>
        <BoatExtra site={site} showObligatory showSelects showOptionals />
      </Box>
      <Visible width={screenXs}>
        <Box>
          <Box
            style={{
              transition: "position 0.3s ease-in-out",
            }}
            background={color.white}
            pad="small"
          >
            {/* <BookingCalendar
              single={single}
              site={site}
              countries={countries}
              yachtTypes={yachtTypes}
              flexibilities={flexibilities}
              showObligatoryExtras
            /> */}
          </Box>
        </Box>
      </Visible>
    </Grid>
  );
}

export default function ContentBoat({
  single,
  site,
  equipment,
  yachtTypes,
  flexibilities,
  block_form,
  countries,
}: PageProps) {
  return (
    <Box pad={{ top: "xlarge" }}>
      <Box width={"xxlarge"} margin="auto">
        <Box width="100%" pad="small">
          <Box margin="small" />
          <PageBoatsContent
            single={single}
            site={site}
            equipment={single.equipment?.value}
            yachtTypes={yachtTypes}
            flexibilities={flexibilities}
            countries={countries}
            block_form={block_form}
          />
        </Box>
      </Box>
    </Box>
  );
}
