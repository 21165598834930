import { Box, Button, DateInput, Form, Grid, RangeSelector, Spinner, Text } from "grommet";
import { FormNext } from "grommet-icons";
import { apiPage, apiPageCount } from "helpers/Fetch";
import { getCode } from "iso-3166-1-alpha-2";
import moment from "moment";
import { formatTimeEnd, formatTimeStart, OffersDate } from "plugins/directus";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { getAllDatesBeforeToday, gridOneColumn, gridSearchHome, margin } from "theme";
import PageProps from "types/page/PageProps";

import { FormSelect } from "./FormSelect";

interface Props {
  // @ts-ignore
  onSubmit?: (data: FilterFormData) => void;
  yachtTypes?: any;
  form_description?: any;
  sailing_area?: any;
  flexibilities?: any;
  booking_type?: any;
  countries?: any;
  bases?: any;
  site?: any;
  isFull?: boolean;
  isSingle?: boolean;
  // @ts-ignore
  showGrid?: boolean;
  yachtId?: string;
}

export interface FilterFormData {
  country?: string;
  currency?: string;
  yachtId?: string;
  product?: string;
  dateTo?: string;
  genoa?: string;
  kind?: string;
  mainsail?: string;
  maxCabins?: any;
  maxHeads?: any;
  maxLength?: any;
  maxPrice?: any;
  minCabins?: any;
  minHeads?: any;
  minLength?: any;
  minPrice?: string;
  model?: string;
  price?: string;
  service?: string;
  dateFrom?: string;
  flexibility?: any;
  sailingAreaId?: number;
  baseFromId?: number;
}

export default function FormSearch({
  yachtTypes,
  flexibilities,
  booking_type,
  countries,
  site,
  form_description,
  sailing_area,
  isFull,
  isSingle,
  bases,
}: Props) {
  const [formState, setFormState] = useState<FilterFormData>({
    country: "",
    currency: "",
    yachtId: "",
    product: "",
    dateTo: "",
    genoa: "",
    kind: "",
    mainsail: "",
    maxCabins: "",
    maxHeads: "",
    maxLength: "",
    maxPrice: "",
    minCabins: "",
    minHeads: "",
    minLength: "",
    minPrice: "",
    model: "",
    price: "",
    service: "",
    dateFrom: "",
  });

  useEffect(() => {
    if (formState.dateFrom) {
      const formattedDateFrom = moment(formState.dateFrom).format("YYYY-MM-DD") + formatTimeStart();
      setFormData((prevData) => ({ ...prevData, dateFrom: formattedDateFrom }));
    }
    if (formState.dateTo) {
      const formattedDateTo = moment(formState.dateTo).format("YYYY-MM-DD") + formatTimeEnd();
      setFormData((prevData) => ({ ...prevData, dateTo: formattedDateTo }));
    }
    if (formState.country) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, country }));
    }
    if (formState.currency) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, currency }));
    }
    if (formState.genoa) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, genoa }));
    }
    if (formState.kind) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, kind }));
    }
    if (formState.mainsail) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, mainsail }));
    }
    if (formState.maxCabins) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, maxCabins }));
    }
    if (formState.maxHeads) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, maxHeads }));
    }
    if (formState.maxLength) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, maxLength }));
    }
    if (formState.maxPrice) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, maxPrice }));
    }
    if (formState.minCabins) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, minCabins }));
    }
    if (formState.minHeads) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, minHeads }));
    }
    if (formState.minLength) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, minLength }));
    }
    if (formState.minPrice) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, minPrice }));
    }
    if (formState.model) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, model }));
    }
    if (formState.price) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, price }));
    }
    if (formState.flexibility) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, flexibility }));
    }
    if (formState.yachtId) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, yachtId }));
    }
    if (formState.sailingAreaId) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, sailingAreaId }));
    }
    if (formState.baseFromId) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, baseFromId }));
    }
    if (formState.product) {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, product }));
      // @ts-ignore
      localStorage.setItem("productName", formState.product);
    } else {
      // @ts-ignore
      setFormData((prevData) => ({ ...prevData, product: "showall" }));
    }
  }, [formState]);

  const [formData, setFormData] = useState<FilterFormData>({
    country: "",
    currency: "",
    dateTo: "",
    genoa: "",
    kind: "",
    mainsail: "",
    maxCabins: "",
    maxHeads: "",
    maxLength: "",
    maxPrice: "",
    minCabins: "",
    minHeads: "",
    minLength: "",
    minPrice: "",
    model: "",
    price: "",
    service: "",
    dateFrom: "",
    flexibility: "",
    yachtId: "",
    product: "",
    sailingAreaId: undefined,
    baseFromId: undefined,
  });

  const handlePersons = (value) => {
    setFormData({ ...formData, minHeads: value[0], maxHeads: value[1] });
  };
  const handleLenght = (value) => {
    setFormData({ ...formData, minLength: value[0], maxLength: value[1] });
  };
  const handleCabins = (value) => {
    setFormData({ ...formData, minCabins: value[0], maxCabins: value[1] });
  };

  const countriesArray = Array.isArray(countries)
    ? countries.reduce((accumulator, item) => {
        accumulator.push({
          label: item.title.value,
          value: getCode(item.title.value),
        });
        return accumulator;
      }, [])
    : [];

  countriesArray.sort((a, b) => a.label.localeCompare(b.label));

  const types = [
    { label: "Show all", value: "showall" },
    ...(Array.isArray(yachtTypes)
      ? yachtTypes.map((item) => ({
          label: item.title.value,
          value: item.title.value,
        }))
      : []),
  ];

  const flexibilityArray = [
    { label: "Show all", value: "showall" },
    ...(Array.isArray(flexibilities)
      ? flexibilities.map((item) => ({
          label: item.title.value,
          value: item.id,
        }))
      : []),
  ];

  const bookingTypeArray = [
    { label: "Show all", value: "showall" },
    ...(Array.isArray(booking_type)
      ? booking_type.map((item) => ({
          label: item.title.value,
          value: item.title.value,
        }))
      : []),
  ];

  function changeSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: value,
    });
  }

  function SearchBoats(formData: FilterFormData) {
    const country = encodeURIComponent(formData.country || "");
    const dateFrom = decodeURIComponent(formData.dateFrom || encodedDateFrom);
    const dateTo = decodeURIComponent(formData.dateTo || encodedDateTo);
    const kind = decodeURIComponent(formData.kind || "");
    const flexibility = encodeURIComponent(formData.flexibility);
    const maxCabins = encodeURIComponent(formData.maxCabins);
    const maxHeads = encodeURIComponent(formData.maxHeads);
    const maxLength = encodeURIComponent(formData.maxLength);
    const minCabins = encodeURIComponent(formData.minCabins);
    const minHeads = encodeURIComponent(formData.minHeads);
    const minLength = encodeURIComponent(formData.minLength);
    const currency = encodeURIComponent(formData.currency || "");
    const yachtId = encodeURIComponent(formData.yachtId || "");
    const product = encodeURIComponent(formData.product || "");
    const sailingAreaId = encodeURIComponent(formData.sailingAreaId || "");
    // @ts-ignore
    const baseFromId = decodeURIComponent(formData.baseFromId || "");

    return `/en/search/result?${country ? `country=${country}` : ""}${
      dateFrom ? `&dateFrom=${dateFrom}` : ""
    }${dateTo ? `&dateTo=${dateTo}` : ""}${kind ? `&kind=${kind}` : ""}${
      flexibility ? `&flexibility=${flexibility}` : ""
    }${maxCabins ? `&maxCabins=${maxCabins}` : ""}${maxHeads ? `&maxHeads=${maxHeads}` : ""}${
      maxLength ? `&maxLength=${maxLength}` : ""
    }${minCabins ? `&minCabins=${minCabins}` : ""}${minHeads ? `&minHeads=${minHeads}` : ""}${
      minLength ? `&minLength=${minLength}` : ""
    }${currency ? `&currency=${currency}` : ""}${yachtId ? `&yachtId=${yachtId}` : ""}${
      product ? `&product=${product}` : ""
    }${sailingAreaId ? `&sailingAreaId=${sailingAreaId}` : ""}${
      baseFromId ? `&baseFromId=${baseFromId}` : ""
    }`;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  const { encodedDateFrom, encodedDateTo, localDays } = OffersDate();

  const today = new Date();
  const startDate =
    (typeof localStorage !== "undefined" && localStorage.getItem("date_start")) ||
    today.toISOString().split("T")[0];
  const endDate =
    (typeof localStorage !== "undefined" && localStorage.getItem("date_end")) ||
    moment(startDate).add(7, "days").format("YYYY-MM-DD");

  const [value, setValue] = useState({
    dateFrom: startDate,
    dateTo: endDate,
  });

  const onChangeCalendar = (event) => {
    const [from, to] = event.value.map((dateString) => new Date(dateString));
    setValue({ dateFrom: from, dateTo: to });
    localStorage.setItem("date_start", from);
    localStorage.setItem("date_end", to);
    // @ts-ignore
    localStorage.setItem("localDays", localDays);
    setFormData({
      ...formData,
      dateFrom: moment(from).format("YYYY-MM-DD") + formatTimeStart(),
      dateTo: moment(to).format("YYYY-MM-DD") + formatTimeEnd(),
    });
  };

  const isValidate = !formData.country;
  return (
    <Box style={{ borderRadius: margin.small }}>
      <Form onSubmit={() => handleSubmit}>
        <Grid columns={isFull ? gridOneColumn() : gridSearchHome()}>
          <Box>
            <Box
              flex
              justify={!isFull && !isSingle ? "center" : "center"}
              width={"100%"}
              pad="small"
            >
              <Box>
                <DateInput
                  color={site.color_accent?.value}
                  value={[
                    value.dateFrom ? value.dateFrom.toString() : "",
                    value.dateTo ? value.dateTo.toString() : "",
                  ]}
                  calendarProps={{
                    firstDayOfWeek: 1,
                    daysOfWeek: true,
                    disabled: getAllDatesBeforeToday(),
                  }}
                  buttonProps={{
                    label: (
                      <Box justify="center" align="center" direction="row">
                        <Moment format="DD.MM.YYYY">{value.dateFrom ?? ""}</Moment>
                        <FormNext />
                        <Moment format="DD.MM.YYYY">{value.dateTo ?? ""}</Moment>
                      </Box>
                    ),
                  }}
                  onChange={onChangeCalendar}
                  style={{ zIndex: 100 }}
                />
              </Box>

              {isFull && (
                <Box flex direction={"row"} width={"100%"}>
                  <FormSelect
                    label="Service"
                    name="product"
                    showSearch
                    onChange={changeSelect}
                    selectData={bookingTypeArray}
                    options={(item) => ({
                      label: item.label,
                      value: item.label,
                    })}
                    formData={formData.product}
                    isForm
                  />
                  <FormSelect
                    label="Flexibility"
                    name="flexibility"
                    onChange={changeSelect}
                    selectData={flexibilityArray.map((item) => ({
                      label: item.label,
                      value: item.value.toString(),
                    }))}
                    options={(item) => ({
                      label: item.label,
                      value: item.value,
                    })}
                    formData={formData.flexibility}
                    isForm
                  />
                </Box>
              )}

              <Text
                margin={{ top: "xsmall" }}
                size="small"
                color={site.color?.value}
                alignSelf="center"
              >
                {form_description}
              </Text>
            </Box>
          </Box>
          <Box width={"100%"} flex direction="row">
            {!isSingle && (
              <Box width={"100%"}>
                <FormSelect
                  label="Country"
                  name="country"
                  onChange={changeSelect}
                  selectData={countriesArray}
                  options={(country) => ({
                    label: country.title.value,
                    value: country.title.value.toString(),
                  })}
                  formData={formData.country}
                  isForm
                  showSearch
                />
              </Box>
            )}
            {!isSingle && (
              <Box width={!isFull ? "small" : "100%"}>
                <FormSelect
                  label="Boat type"
                  name="kind"
                  onChange={changeSelect}
                  selectData={types}
                  options={(item) => ({
                    label: item.title.value,
                    value: item.title.value.toString(),
                  })}
                  formData={formData.kind}
                  isForm
                  showSearch
                />
              </Box>
            )}
          </Box>

          {!isFull && !isSingle && (
            <Box flex justify="center" width={"100%"}>
              <Link to={SearchBoats(formData)}>
                <Box width={"100%"}>
                  <Button type="submit" primary size="large" label="Search" disabled={isValidate} />
                </Box>
              </Link>
            </Box>
          )}
        </Grid>

        {isFull && (
          <FormSelect
            label="Start base"
            name="baseFromId"
            onChange={changeSelect}
            selectData={[
              { label: "Show all", value: "showall" },
              ...(bases
                ? bases.map((item) => ({
                    label: item.title?.value,
                    value: item.title?.value.toString(),
                  }))
                : []),
            ]}
            options={(item) => ({
              label: item.title.value,
              value: item.title.value.toString(),
            })}
            formData={formData.baseFromId}
            isForm
            showSearch
          />
        )}

        {isFull && (
          <Box>
            <Box pad={{ left: "medium", right: "medium", top: "small", bottom: "small" }}>
              <Text size="medium">
                Persons: ({formData.minHeads || 1} - {isValueMax(formData.maxHeads, 20) || 20})
              </Text>
              <RangeSelector
                direction="horizontal"
                min={1}
                max={20}
                values={[formData.minHeads, formData.maxHeads || 20]}
                onChange={(v) => handlePersons(v)}
              />
            </Box>
            <Box pad={{ left: "medium", right: "medium", top: "small", bottom: "small" }}>
              <Text size="medium">
                Number of cabins: ({formData.minCabins || 1} -{" "}
                {isValueMax(formData.maxCabins, 20) || 20})
              </Text>
              <RangeSelector
                direction="horizontal"
                min={1}
                max={20}
                values={[formData.minCabins, formData.maxCabins || 20]}
                onChange={(v) => handleCabins(v)}
              />
            </Box>

            <Box pad={{ left: "medium", right: "medium", top: "small", bottom: "small" }}>
              <Text size="medium">
                Boat length:{" "}
                <Text>
                  ({formData.minLength || 1 + "m"} - {formData.maxLength || 100 + "m"})
                </Text>
              </Text>
              <RangeSelector
                direction="horizontal"
                min={1}
                max={100}
                step={5}
                values={[formData.minLength || 1, formData.maxLength || 100]}
                onChange={(v) => handleLenght(v)}
              />
            </Box>

            <Box margin={{ top: "medium" }}>
              <Link to={SearchBoats(formData)}>
                <Box>
                  <Button type="submit" primary label="Search" disabled={isValidate} />
                </Box>
              </Link>
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );

  function isValueMax(formItem, number): React.ReactNode {
    if (formItem === number) {
      return formItem + " and more";
    }
    return formItem;
  }
}

export function SearchFormInfo({ site }: PageProps) {
  const [info, setInfo] = useState("");
  const [countries, setCountries] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countBoats = await apiPage("/en/count-boats");
        const countCountries = await apiPage("/en/count-countries");
        if (countBoats !== null && countBoats !== null) {
          setInfo(countBoats.title?.value);
          setCountries(countCountries.title?.value);
          setIsLoading(false);
        } else {
          throw new Error("Count of boats is null or undefined");
        }
      } catch (error) {
        console.error("Error fetching:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box align="center">
      {isLoading ? (
        <Spinner size="small" color={site.color?.value} />
      ) : (
        <Text weight={"bold"} alignSelf="center">
          There are <Text color={site.color_accent?.value}>{info}</Text> yachts from{" "}
          <Text color={site.color_accent?.value}>{countries}</Text> countries
        </Text>
      )}
    </Box>
  );
}
