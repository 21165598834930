import { Box, Button, Grid, Grommet, Notification, Spinner, Text } from "grommet";
import { CircleAlert, Compass } from "grommet-icons";
import { apiListCompanies, apiPageCount } from "helpers/Fetch";
import { adminToken, companyStatus } from "plugins/directus";
import React, { useEffect, useState } from "react";
import { FaShip } from "react-icons/fa";
import { color, gridFourColumns, theme } from "theme";
import PageProps from "types/page/PageProps";

import { IconMainsail } from "./content/Icons";

export function CompanyGrid({ site }: PageProps) {
  const [companiesApi, setData] = useState<Company[] | null>(null);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoadingItem, setIsLoadingItem] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const companiesApi = async () => {
      const data = await apiListCompanies("boats");
      return setData(data);
    };

    companiesApi();
  }, []);

  async function handleRequest(url, itemName, companyId, actionMethod, errorMessage) {
    if (
      !isLoadingItem[itemName] &&
      Object.keys(isLoadingItem).filter((key) => isLoadingItem[key]).length < 5
    ) {
      try {
        setIsLoadingItem((prevIsLoadingItem) => ({
          ...prevIsLoadingItem,
          [itemName]: true,
        }));

        const response = await fetch(url, {
          method: actionMethod,
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          setShowNotification(itemName);
          console.log(`Item ${actionMethod === "DELETE" ? "deleted" : "updated"} successfully`);
          const patchResponse = await companyStatus("company", companyId, 1);
        } else {
          console.log(`Error ${errorMessage}`);

          const patchResponse = await companyStatus("company", companyId, 0);

          // @ts-ignore
          if (patchResponse.ok) {
            console.log("PATCH request successful");
          } else {
            console.log("Error in PATCH request");
          }
        }
      } catch (error) {
        console.log("Error:", error);
        setError(itemName);
      } finally {
        setIsLoadingItem((prevIsLoadingItem) => ({
          ...prevIsLoadingItem,
          [itemName]: false,
        }));
      }
    }
  }

  async function handleAddSingle(link, itemName, companyId) {
    await handleRequest(link, itemName, companyId, "DELETE", "adding item");
    await handleRequest(link, itemName, companyId, "POST", "adding item");
  }

  async function handleUpdateAllBoats(link, itemName, companyId) {
    await handleRequest(link, itemName, companyId, "DELETE", "updating item");
    await handleRequest(link, itemName, companyId, "POST", "updating item");
  }

  async function updateContent(apiMethod, content) {
    try {
      // Then, send the POST request
      const postResponse = await fetch(`/api/${content}`, {
        method: apiMethod,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (postResponse.ok) {
        console.log("POST request successful");
      } else {
        console.log("Error in POST request");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const [selectedId, setSelectedId] = useState(null);

  interface Company {
    id: any;
    status: any;
    country: any;
    city: any;
    title: any;
    name: any;
    subpages: any;
  }

  const sortedData: Array<Company> = [...(companiesApi || [])].sort((a, b) => a.name - b.name);
  const filteredData = selectedId
    ? sortedData.filter((item) => item.name === selectedId && item.status === 0)
    : sortedData;

  const errorData = sortedData.filter((item) => item.status === 0 || item.subpages === 0);

  const dataToShow = [...filteredData];

  function updateErrorBoats() {
    setIsUpdating(true);

    errorData.forEach((item, index) => {
      if (!isLoadingItem[item.name]) {
        setTimeout(() => {
          handleUpdateAllBoats(`/api/boats/${item.name}`, item.name, item.id);
        }, index * 1000);
      }
    });
  }

  function updateSuccessBoats() {
    setIsUpdating(true);

    filteredData.forEach((item, index) => {
      if (!isLoadingItem[item.name]) {
        setTimeout(() => {
          handleUpdateAllBoats(`/api/boats/${item.name}`, item.name, item.id);
        }, index * 1000);
      }
    });
  }

  const isloading = dataToShow.length === 0;
  return (
    <Grommet theme={theme(site.color, site.color_accent)}>
      <Box direction="row" gap="small" pad="medium">
        <Button
          secondary
          onClick={() => updateContent("POST", "companies/all")}
          label={"Update Companies"}
          size="medium"
          icon={<Compass />}
          disabled={isloading}
        />
        <Button
          secondary
          onClick={() => updateBases()}
          label={"Update Bases"}
          size="medium"
          icon={<Compass />}
          disabled={isloading}
        />
        <Button
          secondary
          onClick={() => updateContent("POST", "booking-manager/equipment")}
          label={"Update equipment"}
          size="medium"
          icon={<Compass />}
          disabled={isloading}
        />
        <Button
          secondary
          size="medium"
          onClick={isUpdating ? () => setIsUpdating(false) : updateSuccessBoats}
          label={"Update boats"}
          icon={<IconMainsail size={24} color={color.main} />}
          disabled={isloading}
        />
        <Button
          secondary
          size="medium"
          onClick={() => apiPageCount("boat", "boats")}
          label={"Update counting"}
          icon={<FaShip size={24} color={color.main} />}
          disabled={isloading}
        />
        <Button
          secondary
          color={"status-critical"}
          size="medium"
          onClick={isUpdating ? () => setIsUpdating(false) : updateErrorBoats}
          label={"Check boats"}
          icon={<IconMainsail size={24} color={color.main} />}
          disabled={isloading}
        />
      </Box>

      {isloading && (
        <Box width={"100%"} height={"small"} flex align="center" justify="center">
          <Spinner size="large" color={color.main} />
        </Box>
      )}

      <Grid columns={gridFourColumns()}>
        {dataToShow.map((item) => {
          const noBoatsInCmpany = item.status === 0 || item.subpages === 0;
          return (
            <Box
              key={item.id}
              margin={{
                bottom: "medium",
              }}
              pad="small"
              style={{ position: "relative" }}
            >
              <Text size="large">{item.title?.value}</Text>
              <Text size="small">{item.city}</Text>
              <Text size="small">Company id: {item.name}</Text>
              <Text size="small">Site Id: {item.id}</Text>
              <Box direction="row" align="center">
                <IconMainsail size={24} color={color.main} />
                <Text size="small">{item.subpages}</Text>
              </Box>
              {item.status === 0 && (
                <Text size="small">
                  <CircleAlert size="small" /> {item.status}
                </Text>
              )}

              <Box direction="row" gap="small">
                <Button
                  primary
                  color={noBoatsInCmpany ? color.red : color.main}
                  onClick={() => handleAddSingle(`/api/boats/${item.name}`, item.name, item.id)}
                  label={"Add boats"}
                  size="small"
                />
              </Box>
              {error === item.name && (
                <Notification
                  toast
                  onClose={() => setError(null)}
                  message={`Error updating ${item.title?.value} boats`}
                  key={`error-${item.name}`}
                />
              )}
              {showNotification === item.name && (
                <Notification
                  toast={{ position: "top-right" }}
                  onClose={() => setData(null)}
                  message={`${item.title?.value} boats are updated`}
                  key={`success-${item.name}`}
                />
              )}
            </Box>
          );
        })}
      </Grid>
    </Grommet>
  );

  async function updateBases() {
    try {
      await updateContent("DELETE", "booking-manager/bases");
      await updateContent("POST", "booking-manager/bases");
    } catch (error) {
      console.error("Error updating bases:", error);
      // Handle the error as needed
    }
  }
}
