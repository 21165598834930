import { Box, Grommet, Spinner } from "grommet";
import { apiPage, apiSearch, isSearchPage } from "helpers/Fetch";
import React, { useEffect, useState } from "react";
import PageProps from "types/page/PageProps";

import { theme } from "../theme";
import Content from "./Content";
import Footer from "./Footer";

export default function Page({ page, path, header, site }: PageProps) {
  const [apiData, setApiData] = useState<any>(null);
  const [searchData, setSearchData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      if (isSearchPage) {
        const setSearch = await apiSearch(window.location.search);
        setSearchData(setSearch);
      }
    };
    fetchData();
  }, [window.location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = await apiPage(path);
        setApiData(api);
      } catch (error) {
        // Handle error here
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [path]);

  if (isLoading) {
    return (
      <Grommet theme={theme(site.color?.value, site.color_accent?.value)}>
        <Box flex width={"100vw"} height={"100vh"} align={"center"} justify={"center"}>
          <Spinner size="large" color={site.color?.value} />
        </Box>
      </Grommet>
    );
  }

  if (!apiData) {
    return null;
  }

  return (
    <Grommet theme={theme(site.color?.value, site.color_accent?.value)}>
      <Content page={apiData} searchData={searchData} allPages={header} list={page} site={site} />
      <Footer header={header} site={site} />
    </Grommet>
  );
}
