import { Box, Spinner } from "grommet";
import React from "react";
import { useInView } from "react-intersection-observer";
import { color } from "theme";

const ViewPort = ({ children }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <Box animation="fadeIn" ref={ref} width="100%">
      {inView ? <div>{children}</div> : <Spinner size="medium" color={color.main} />}
    </Box>
  );
};

export default ViewPort;
