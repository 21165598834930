import { Box, Text } from "grommet";
import { Mail, Map, Phone } from "grommet-icons";
import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import PageProps from "types/page/PageProps";

import { color, responsiveSize } from "../theme";
import { HeaderLogo, HeaderNavigation } from "./Navigation";
const Footer = ({ header: allPages, site }: PageProps) => {
  function ContentFooter() {
    if (site && site?.footer) {
      return (
        <Box direction="row" align="center" gap="small">
          <Map />
          <Text dangerouslySetInnerHTML={{ __html: site?.footer.value }} />
        </Box>
      );
    }
    return null;
  }

  function ContentPhone() {
    if (site && site?.phonenumber) {
      return (
        <Box direction="row" align="center" gap="small">
          <Phone />
          <Text size="medium" dangerouslySetInnerHTML={{ __html: site?.phonenumber?.value }} />
        </Box>
      );
    }
    return null;
  }

  function ContentEmail() {
    if (site && site?.email) {
      return (
        <Box direction="row" align="center" gap="small">
          <Mail />
          <Text size="medium" dangerouslySetInnerHTML={{ __html: site?.email?.value }} />
        </Box>
      );
    }
    return null;
  }

  function ContentSocial() {
    if (site && site.social) {
      return (
        <Box direction="row" align="center" justify="center" gap="small">
          {site.social.map((item, index) => (
            <Box key={index} color="white">
              <a href={item.social_url?.value} target="_blank" rel="noreferrer">
                {item.icons.value === "telegram" && <FaTelegram size={24} color="white" />}
                {item.icons.value === "facebook" && <FaFacebook size={24} color="white" />}
                {item.icons.value === "twitter" && <FaTwitter size={24} color="white" />}
                {item.icons.value === "instagram" && <FaInstagram size={24} color="white" />}
                {item.icons.value === "linkedin" && <FaLinkedin size={24} color="white" />}
                {item.icons.value === "whatsapp" && <FaWhatsapp size={24} color="white" />}
              </a>
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  }

  return (
    <Box pad={"medium"} background={color.dark}>
      <Box
        flex
        direction={responsiveSize(["xsmall", "small"]) ? "column" : "row"}
        align="center"
        alignSelf="center"
        justify="between"
        width={"xlarge"}
        gap="small"
      >
        <HeaderLogo site={site} path={"/"} logoHeight={"30px"} />
        <HeaderNavigation header={allPages} fontSize={"small"} />
      </Box>
      <Box direction="row" justify="evenly" align="center" margin={"medium"}>
        <ContentPhone />
        <ContentEmail />
        <ContentFooter />
      </Box>
      <ContentSocial />
    </Box>
  );
};
export default Footer;
