import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Box, Text } from "grommet";
import { getCode } from "iso-3166-1-alpha-2";
import mapboxgl from "mapbox-gl";
import { mapBoxToken, OffersDate } from "plugins/directus";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { color } from "theme";

interface Props {
  item?: any;
  showMap?: boolean;
}

const MapboxGLComponent = ({ item }: Props) => {
  const [geojsonSource, setGeojsonSource] = useState([]);
  const router = location;

  function showMap() {
    if (router.pathname.includes("#map")) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (showMap()) {
      mapboxgl.accessToken = mapBoxToken;
      const nav = new mapboxgl.NavigationControl();
      const GeolocateControl = new mapboxgl.GeolocateControl();
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
      });

      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/siilak/clog30rex006k01qrdmvt52j9",
        center: [9.0, 41.0], // Center of Europe
        zoom: 5, // Zoom level for Europe
        minZoom: 4,
        maxZoom: 17,
        pitchWithRotate: true,
        trackResize: true,
        hash: true,
        scrollZoom: false,
      });

      map.on("load", function () {
        if (geojsonSource) {
          map.addSource("example-source", {
            type: "geojson",
            // @ts-ignore
            data: geojsonSource,
          });

          map.addLayer({
            id: "example-layer",
            type: "circle",
            source: "example-source",
            paint: {
              "circle-radius": 4,
              "circle-color": color.orange,
            },
          });

          map.on("mouseenter", "example-layer", function (e) {
            // Change the cursor style as a visual cue
            map.getCanvas().style.cursor = "pointer";

            const feature = e.features ? e.features[0] : undefined;

            // @ts-ignore
            const coordinates = feature.geometry.coordinates.slice();

            // @ts-ignore
            const properties = feature.properties;
            console.log(feature);

            const { encodedDateFrom, encodedDateTo } = OffersDate();

            const popup = new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(
                // @ts-ignore
                `<a href="/en/search?dateFrom=${encodedDateFrom}&dateTo=${encodedDateTo}&country=${getCode(
                  properties?.countryName ?? ""
                )}&baseFromId=${properties?.homeBaseId ?? ""}&flexibility=6">
                  <div class="uk-text-center">
                    <div class="uk-text-small font-bold">${properties?.homeBase ?? ""}</div>
                    <div>${properties?.countryName ?? ""}</div>
                    <div>Yachts: <span class="font-bold">${properties?.count ?? ""}</span></div>
                  </div>
                </a>`
              )
              .addTo(map);
          });

          map.on("mouseleave", "example-layer", function () {
            const popup = new mapboxgl.Popup();
            map.getCanvas().style.cursor = "";
            popup.remove();
          });
        }
      });

      // Add geocoder control to map
      map.addControl(geocoder, "top-left");
      map.addControl(nav, "top-right");
      map.addControl(GeolocateControl, "top-right");
      return () => map.remove();
    }
  }, [geojsonSource]);

  useEffect(() => {
    fetch("/api/boats/map")
      .then((response) => response.json())
      .then(async (data) => {
        const promises = data.map(async (item) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [item.longitude, item.latitude],
            },
            properties: {
              homeBase: item.homeBase,
              homeBaseId: item.baseId,
              countryName: item.country,
              count: item.count,
            },
          };
        });

        const formattedData = {
          type: "FeatureCollection",
          features: await Promise.all(promises),
        };

        // @ts-ignore
        setGeojsonSource(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching GeoJSON data:", error);
      });
  }, []);

  return (
    <Box>
      <Box
        margin={{ top: "medium", bottom: "auto", left: "auto", right: "auto" }}
        width={showMap() ? "xxlarge" : "xlarge"}
        style={
          !showMap()
            ? {
                position: "relative",
                borderRadius: "10px",
                backgroundImage: `url(${item.image?.large})`,
              }
            : undefined
        }
        height={showMap() ? "large" : "small"}
      >
        {!showMap() && item.translations[0].title && (
          <Box margin={{ top: "small" }} flex justify="center" align="center">
            <Box
              style={{
                position: "absolute",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
              }}
              width={"100%"}
            >
              <Link to="/#map">
                <Text
                  size="4xl"
                  textAlign="center"
                  color={color.white}
                  margin={{ top: "small", bottom: "small" }}
                >
                  {item.translations[0].title}
                </Text>
              </Link>

              <Link to="/#map">
                <Text
                  size="xlarge"
                  color={color.white}
                  textAlign="center"
                  margin={{ top: "small", bottom: "small" }}
                >
                  {item.translations[0].description}
                </Text>
              </Link>
            </Box>
          </Box>
        )}
        {showMap() && (
          <Box
            id="map"
            width={"100%"}
            height={"large"}
            style={{ borderRadius: "10px", width: "100%" }}
          />
        )}
      </Box>
    </Box>
  );
};

export default MapboxGLComponent;
