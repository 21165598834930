import { Box, CheckBox, FormField, Grommet, Select, Text, TextInput } from "grommet";
import React, { useEffect, useState } from "react";

import { color, margin, theme } from "../../theme";

interface Props {
  products?: any;
  maxPersons?: number;
  site: any;
  showSummary?: boolean;
  showSelects?: boolean;
  showOptionals?: boolean;
  showObligatory?: boolean;
  showPeople?: boolean;
}

function BoatExtraItem(props: {
  label: any;
  selectedOptionalExtras: any;
  index: any;
  onChange: () => void;
  description?: any;
  checked?: boolean;
  disabled?: boolean;
  price: any;
  currency: any;
  unit: any;
  person: any;
  calculatePerson: (e) => void;
  itemAmount: any;
  itemAmounts?: any;
}) {
  function capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <Box pad={"xsmall"}>
      <Box flex direction="row" justify="between" pad={"xsmall"} border="all" round="xsmall">
        <Box width="large">
          <CheckBox
            label={capitalizeFirstLetter(props.label)}
            disabled={props.disabled}
            checked={props.checked}
            onChange={props.onChange}
            toggle
          />
        </Box>

        <Box>
          <Text size="medium" margin="xsmall" color="accent">
            <Box flex direction="row" align="center">
              {props.price === 0 && (
                <Box>
                  <Box
                    background={color.black}
                    justify="center"
                    align="center"
                    pad="xsmall"
                    style={{ color: color.yellow, borderRadius: margin.small }}
                  >
                    <Text color={color.yellow} size="1xl">
                      <Text weight={"bold"}>Free</Text>
                    </Text>
                  </Box>
                </Box>
              )}
              {props.price !== 0 && props.price + " " + props.currency}

              <Text size="xsmall" margin="xsmall">
                {props.unit}
              </Text>
            </Box>
            <Text color={color.grayDark} size="small">
              {props.description}
            </Text>
          </Text>
          {props.person && (
            <Box flex direction="row" align="center">
              <TextInput
                placeholder="Quantity"
                width="xsmall"
                size="small"
                onChange={props.calculatePerson}
              />
              <Text size="medium" weight="bold">
                {props.itemAmounts && (props.itemAmounts[props.index] || 0) + "€"}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function BoatExtra({
  products,
  site,
  showSummary,
  showOptionals,
  showObligatory,
  showSelects,
}: Props) {
  const [selectedObligatoryExtras, setSelectedObligatoryExtras] = useState([]);
  const [selectedOptionalExtras, setSelectedOptionalExtras] = useState([]);
  const [obligatorySummary, setObligatorySummary] = useState(0);
  const [person, setPerson] = useState("");
  const [itemAmount, setItemAmount] = useState(Number);
  const [itemAmounts, setItemAmounts] = useState({});

  // Update total price when optional extras change
  useEffect(() => {
    const price = extrasAmount();
    setTotalPrice(price);
  }, [selectedOptionalExtras]);

  const handleToggleCheckbox = (index: string | number, obligatory: any) => {
    if (obligatory) {
      const newSelectedObligatoryExtras = [...selectedObligatoryExtras];
      // @ts-ignore
      newSelectedObligatoryExtras[index] = !newSelectedObligatoryExtras[index];
      setSelectedObligatoryExtras(newSelectedObligatoryExtras);
    } else {
      const newSelectedOptionalExtras = [...selectedOptionalExtras];
      // @ts-ignore
      newSelectedOptionalExtras[index] = !newSelectedOptionalExtras[index];
      setSelectedOptionalExtras(newSelectedOptionalExtras);
    }
  };

  const [totalPrice, setTotalPrice] = useState(0);

  function isPerson(unit) {
    if (["per person", "per week person", "per booking person"].includes(unit)) {
      return true;
    }
    return false;
  }

  function isDays(unit) {
    if (["per day", "per night"].includes(unit)) {
      return true;
    }
    return false;
  }

  function isPeopleSelected(unit) {
    if (["per night person"].includes(unit)) {
      return true;
    }
    return false; // Add a return statement here
  }

  const calculatedPersons = (unit: string) => {
    if (["per night person"].includes(unit)) {
      if (typeof window !== "undefined") {
        // Only run this code in the browser where localStorage is available
        return window.localStorage.getItem("how_many_people") || 1;
      }
      return 1; // Default to 1 if localStorage is not available
    }

    return 0; // Add a return statement for the case where none of the if conditions are true
  };

  const calculatedDays = (unit: string) => {
    if (["per day", "per night"].includes(unit)) {
      if (typeof window !== "undefined") {
        return window.localStorage.getItem("numberOfDays") || 1;
      }
      return 1; // Default to 1 if localStorage is not available
    }

    return 0; // Add a return statement for the case where none of the if conditions are true
  };

  const calculateItemPrice = (item) => {
    let calculatedTotalPrice = 0;

    item.extras.forEach(({ price, obligatory, unit }, index) => {
      if (obligatory && (!selectedObligatoryExtras || !selectedObligatoryExtras[index])) {
        if (isPeopleSelected(unit)) {
          const people = calculatedPersons(unit);
          if (typeof people === "string") {
            calculatedTotalPrice += price * parseInt(people);
          }
        } else {
          calculatedTotalPrice += price;
        }
      }
    });

    if (typeof window !== "undefined") {
      localStorage.setItem("calculatedTotalPrice", String(calculatedTotalPrice));
    }
    return calculatedTotalPrice;
  };

  const extrasAmount = () => {
    let totalPrice = 0;
    selectedOptionalExtras.forEach((isSelected, index) => {
      if (isSelected) {
        const itemAmount = itemAmounts[index];
        if (itemAmount) {
          totalPrice += itemAmount;
        } else {
          const { price, unit } = products.flatMap((item) => item.extras)[index];
          if (isDays(unit)) {
            const days = calculatedDays(unit);
            // @ts-ignore
            totalPrice += price * days;
          } else {
            totalPrice += price;
          }
        }
      }
    });
    if (typeof window !== "undefined") {
      localStorage.setItem("totalPrice", String(totalPrice));
    }
    return totalPrice;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const storedTotalPrice = localStorage.getItem("totalPrice");
      if (storedTotalPrice) {
        const totalPriceNumber = parseInt(storedTotalPrice);
        setTotalPrice(totalPriceNumber);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function productName() {
    if (typeof window !== "undefined") {
      return window.localStorage.getItem("productName");
    }
    // Add a return statement here to handle the case when typeof window is "undefined"
    return null;
  }

  return (
    <Box>
      {products &&
        products?.map((item, i) => (
          <Box key={i}>
            {showObligatory && item.name === productName() && item.extras.length > 0 && (
              <Box>
                <Box>
                  <Text size="xlarge" color={site.color_accent?.value} margin={{ top: "small" }}>
                    {item.name}
                  </Text>
                  {item.extras.length < 0 && (
                    <Text
                      size="xlarge"
                      margin={{ top: "small", bottom: "small" }}
                      color={site.color_accent?.value}
                    >
                      Obligatory extras
                    </Text>
                  )}
                </Box>

                <Box>
                  {showSelects &&
                    item.name === productName() &&
                    item.extras.map(({ name, price, currency, obligatory, unit }, index) => (
                      <Box key={index}>
                        {obligatory === true && (
                          <BoatExtraItem
                            key={index}
                            label={name}
                            selectedOptionalExtras={selectedOptionalExtras}
                            index={index}
                            checked={!selectedObligatoryExtras[index]}
                            disabled={true}
                            onChange={() => handleToggleCheckbox(index, obligatory)}
                            price={price}
                            currency={currency}
                            unit={unit}
                            person={isPerson(unit)}
                            calculatePerson={(e) => {
                              const inputValue = parseFloat(e.target.value);
                              if (!isNaN(inputValue)) {
                                setPerson(inputValue.toString());
                                const totalPrice = inputValue * price;
                                setItemAmount(totalPrice);
                              }
                            }}
                            itemAmount={itemAmount || price}
                          />
                        )}
                      </Box>
                    ))}
                  {isBoatType(item) && (
                    <Text size="medium" margin={{ top: "small" }}>
                      <Text>Obligatory extras price: </Text>
                      {calculateItemPrice(item).toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </Text>
                  )}
                </Box>
              </Box>
            )}

            {showOptionals && (
              <Box id={i}>
                {item.name === productName() && item.extras.length > 0 && (
                  <Text
                    size="xlarge"
                    margin={{ top: "small", bottom: "small" }}
                    color={site.color_accent?.value}
                  >
                    Optional extras
                  </Text>
                )}

                <Box>
                  {showSelects &&
                    item.name === productName() &&
                    item.extras.map(
                      ({ name, price, currency, unit, obligatory, description }, index) => (
                        <Box key={index}>
                          {!showSummary && obligatory === false && (
                            <BoatExtraItem
                              key={name}
                              label={name}
                              selectedOptionalExtras={selectedOptionalExtras}
                              index={index}
                              checked={selectedOptionalExtras[index]}
                              onChange={() => handleToggleCheckbox(index, obligatory)}
                              description={description}
                              price={price}
                              currency={currency}
                              unit={unit}
                              person={isPerson(unit)}
                              calculatePerson={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                if (!isNaN(inputValue)) {
                                  setPerson(inputValue.toString());
                                  const totalPrice = inputValue * parseFloat(price);
                                  setItemAmounts((prevState) => ({
                                    ...prevState,
                                    [index]: totalPrice,
                                  }));
                                }
                              }}
                              itemAmount={itemAmounts}
                              itemAmounts={itemAmounts}
                            />
                          )}
                        </Box>
                      )
                    )}
                </Box>

                {item.name === productName() && (
                  <Text size="medium" margin={{ top: "small" }}>
                    Selected extras price:{" "}
                    {typeof localStorage !== "undefined" &&
                      localStorage.getItem("totalPrice") != null &&
                      localStorage.getItem("totalPrice") + " " + item.extras[0].currency}
                  </Text>
                )}
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
}
function isBoatType(item: any) {
  const productName =
    typeof localStorage !== "undefined" ? localStorage.getItem("productName") : null;
  return item.name === productName;
}
