import { MyAvatar } from "components/MyProfile";
import { Box, Button, Layer, Text } from "grommet";
import { Menu } from "grommet-icons";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { isLoggedIn } from "store/api";
import styled from "styled-components";

import { color, Hidden, margin, responsiveSize, screenSm, Visible } from "../theme";
import PageEntry from "../types/page/PageEntry";
import PageProps from "../types/page/PageProps";

export default function Navigation({ site, header, path, fontSize }: PageProps) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMenuClick = () => {
    setShowMobileMenu(true);
  };

  const handleCloseMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <Box flex direction="row" pad="small" align="center" justify="between" width={"xxlarge"}>
      <HeaderLogo site={site} path={path} />

      <Hidden width={screenSm}>
        <HeaderNavigation header={header} fontSize={fontSize} />
      </Hidden>

      <Box>
        <MyAvatar
          site={site}
          color={isLoggedIn(site) ? site.color?.value : site.color_accent?.value}
        />
      </Box>

      <Visible width={screenSm}>
        <Button icon={<Menu color="white" />} onClick={handleMenuClick} />
      </Visible>

      {showMobileMenu && (
        <Layer
          full
          position="right"
          modal
          onClickOutside={handleCloseMenu}
          onEsc={handleCloseMenu}
          responsive={false}
          animation="slide"
          background={site.color?.value}
        >
          <Box align="center" pad="medium">
            <HeaderLogo onClick={handleCloseMenu} site={site} path={path} />
            <Box align="center" pad="medium" onClick={handleCloseMenu} focusIndicator={false}>
              <HeaderNavigation header={header} fontSize={fontSize} />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  );
}

export function HeaderLogo({ site, path, logoHeight, onClick }: PageProps) {
  if (site && site.logo && site.logo[0]?.small) {
    return (
      <Box>
        <NavLink to={path} onClick={onClick}>
          <img
            src={site.logo[0]?.small}
            alt=""
            style={{ padding: "4px", height: logoHeight || "40px" }}
          />
        </NavLink>
      </Box>
    );
  }
  return null; // or return a default logo
}

export function HeaderNavigation({ header, fontSize }: PageProps) {
  const ActiveNavLink = styled(NavLink)`
    color: ${color.white};
    text-transform: uppercase;
    margin-right: ${margin.small};
    position: relative;

    &.active {
      border-bottom: 2px solid ${color.white};
    }
  `;
  return (
    <Box
      flex
      direction={responsiveSize(["xsmall", "small"]) ? "column" : "row"}
      justify="evenly"
      alignSelf="center"
    >
      {responsiveSize(["xsmall", "small"])
        ? header?.map((page: PageEntry, i: number) => (
            <NavLink
              key={i}
              to={"" + page.path}
              style={{
                color: color.white,
                textTransform: "uppercase",
                marginRight: margin.small,
              }}
            >
              <Text size={fontSize}>{page.menu_title?.value}</Text>
            </NavLink>
          ))
        : header?.map((page: PageEntry, i: number) => (
            <ActiveNavLink
              key={i}
              to={"" + page.path}
              style={{
                color: color.white,
                textTransform: "uppercase",
                marginRight: margin.small,
              }}
            >
              <Text size={fontSize}>{page.menu_title?.value}</Text>
            </ActiveNavLink>
          ))}
    </Box>
  );
}
