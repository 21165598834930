import { Box, Grid, Grommet, Image, Spinner, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { color, gridTwoColumns, scrollToTop, theme } from "../theme";
import ViewPort from "./ViewPort";

interface Props {
  list: any;
  title?: string;
}

export function CardBackground(props: { background?: any }) {
  return (
    <div
      style={{
        background: props.background,
        position: "absolute",
        content: "111",
        width: "100%",
        height: "100%",
      }}
    />
  );
}

export default function List({ list, title }: Props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`/api/list/en/${list}/900`);
      const response = await res.json();
      if (Array.isArray(response)) {
        setData(response as any);
      }
    }
    fetchData();
  }, []);

  if (!data) {
    return (
      <Box height="small">
        <Box flex justify="center" align="center">
          <Spinner size="medium" color={color.main} />
        </Box>
      </Box>
    );
  }

  return (
    <Grommet theme={theme("brand", "accent")}>
      <Box>
        {title && list && (
          <Box margin="auto" pad={"medium"}>
            <Text size="xxlarge" color="brand" className="font-bold">
              {title}
            </Text>
          </Box>
        )}

        <Grid columns={gridTwoColumns()}>
          {list &&
            data?.map((item: any, key: number) => {
              return (
                <Box
                  key={key}
                  height={"medium"}
                  border={{ side: "bottom", color: "brand" }}
                  style={{ position: "relative" }}
                >
                  <ViewPort>
                    <Box
                      className="app-overlay-background"
                      height={"auto"}
                      style={{ objectFit: "cover" }}
                    >
                      {item.images[0]?.thumb && (
                        <Image
                          fit="cover"
                          width={"100%"}
                          height={"auto"}
                          src={item.images[0]?.thumb}
                          alt=""
                        />
                      )}
                    </Box>
                    <Box
                      animation="fadeIn"
                      flex
                      direction="column"
                      style={{
                        position: "relative",
                        zIndex: 2,
                        textAlign: "center",
                      }}
                    >
                      <Link to={item?.path} onClick={() => scrollToTop()}>
                        <Box
                          flex
                          justify="center"
                          align="center"
                          pad="small"
                          width={"large"}
                          key={item.path}
                          height={"medium"}
                        >
                          <Text
                            color="white"
                            size="xxlarge"
                            textAlign="center"
                            className="font-bold"
                            style={{ zIndex: 1 }}
                          >
                            {item.title?.value}
                          </Text>
                          <Text color="white" textAlign="center" style={{ zIndex: 1 }}>
                            {item?.date}
                          </Text>
                          {item?.body?.value && (
                            <Text
                              color="white"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item?.body?.value && item.body.value.length > 200
                                    ? item.body.value.substring(0, 200) + "..."
                                    : item.body.value,
                              }}
                              style={{ zIndex: 1000 }}
                            />
                          )}
                        </Box>
                      </Link>
                    </Box>
                  </ViewPort>
                </Box>
              );
            })}
        </Grid>
      </Box>
    </Grommet>
  );
}
