import moment from "moment";
import ReactGA from "react-ga4";

export const siteDomain = process.env.NEXT_PUBLIC_FRONTEND_URL;

export const apiDomain = process.env.PUBLIC_API_URL;
export const apiToken = "xI0PNnVCdKc1MMWpbrsClUCwbuwOIUTl";

export const bookingManagerUrl = "https://www.booking-manager.com/api/v2";
export const bookingManagerToken =
  "1731-92d96cd2c40dc4207f931bb85de43764affa256c11ffd0c013bcba415d105061cab8ab005aab041a63e4018a6a49c4c5fa39f12e929395e57a3024c5fee22602";

export const pathLogin = "/user/login";

export const apiKey = "8fa29d910399023fd60c374403672c2f";
export const roleAdmin = "e763b1d2-b0b6-4b47-8a9d-edee857cdc49";

export const STRIPE_PK =
  process.env.STRIPE_PK ||
  "pk_test_51JT3wWHAeKLuOChrDcCWnDkmEIZ3RFuCo2kEd1dKNMM7M6gGGwWe1WOSaOZckhsMQ2jEddPUCSl4H0xEWF8G5H1x00tUDkNrde";

export function imageOriginal(item: any): string {
  return `/assets/${item.id}`;
}

export function imageLarge(item: any): string {
  return `/assets/${item.id}?width=1200`;
}

export function imageOriginalSingle(item: any): string {
  return `/assets/${item}`;
}

export function imageLargeSingle(item: any): string {
  return `/assets/${item}?width=1200`;
}

export function imageThumb(item: any): string {
  return `/assets/${item.id}?width=480&height=480&crop`;
}

export function imageThumbSingle(item: any): string {
  return `/assets/${item}?width=480&height=480&crop`;
}

export function imageThumbLargeSingle(item: any): string {
  return `/assets/${item}?width=780&height=780&crop`;
}

export function playerAnalytics(station: any, track: any) {
  ReactGA.event("Player", {
    station,
    track,
  });
}

export function isVideo(item: any) {
  return item === "video/mp4";
}

export function isAudio(item: any) {
  return item === "audio/mpeg" || item === "audio/mp3";
}

export function isImage(item: any) {
  return item === "image/jpeg" || item === "image/png";
}

export function generateScreenURL() {
  const allowedLetters = "1234567890abcdefghijklmnopqrstuvwxyz";
  const tokenLength = 5;
  let token = "";

  for (let i = 0; i < tokenLength; i++) {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * allowedLetters.length);
    } while (allowedLetters[randomIndex] === "U" || allowedLetters[randomIndex] === "I");

    token += allowedLetters[randomIndex];
  }

  return token;
}

export function isLoggedIn(site: any) {
  return site.userRole === "guest";
}

export function getAllDatesBeforeToday() {
  const moment = require("moment");
  const startOfMonth = moment().startOf("month").toISOString();
  const yesterday = moment().subtract(1, "day").toISOString();
  return [[startOfMonth, yesterday]];
}
export function formatTimeStart() {
  return "T00:00:00.00";
}

export function formatTimeEnd() {
  return "T00:00:00.00";
}

export async function uploadFile() {
  const form = document.querySelector("#upload-file");

  if (form && form instanceof HTMLFormElement) {
    form.addEventListener("submit", async (event) => {
      event.preventDefault();

      const formData = new FormData(event.target as HTMLFormElement);
      const response = await fetch("/api/files", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }
    });
  }
}

export async function foldersApi(id) {
  const res = await fetch(`/folders/${id}`);
  const page = await res.json();
  const post = page.data;

  return {
    props: {
      single: post,
      id: post.id,
    },
  };
}

export function formatDateShort(date: string | number | Date): string {
  return moment(date).format("YYYY-MM-DD");
}

export function formatDateNormal(date: string | number | Date): string {
  return moment(date).format("D MMMM YYYY");
}

export function formatTimeShort(date: string | number | Date): string {
  return moment(date).format("HH:mm");
}

export function formatDateTime(date: string | number | Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Intl.DateTimeFormat("et-ET", options).format(new Date(date));
}

export async function apiFields(item: string) {
  const response = await fetch(`/api/fields/${item}`);
  const pageData = await response.json();
  const data = pageData.data;
  return data;
}

export async function setCounter(collectionName: any, itemId: any, counter: any, fakeCount?: any) {
  const newCounter = fakeCount || ++counter;

  const response = await fetch(`/api/items/${collectionName}/${itemId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ counter: newCounter }),
  });

  if (!response.ok) {
    throw new Error("Failed to update counter");
  }
}

export function isFrontPage() {
  const router = location;
  return router.pathname === "/";
}

export function isNotFrontPage() {
  const router = location;
  return router.pathname !== "/";
}
