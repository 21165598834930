import { Box, grommet, ResponsiveContext } from "grommet";
import { deepMerge } from "grommet/utils";
import { useContext, useEffect, useState } from "react";

export function screenSize() {
  return useContext(ResponsiveContext);
}

export function responsiveSize(sizes) {
  const setSize = useContext(ResponsiveContext);
  if (sizes.includes(setSize)) {
    return true;
  }
  return false;
}

export function gridCoutries() {
  const size = useContext(ResponsiveContext);
  if (size === "xlarge" || size === "large") {
    return ["15%", "20%", "25%", "20%", "20%"];
  }
  if (size === "medium") {
    return ["15%", "20%", "25%", "20%", "20%"];
  }
  if (size === "xsmall" || size === "small") {
    return ["1/2", "1/2"];
  }
  return []; // add a default return statement
}

export function gridTwoColumns() {
  const size = useContext(ResponsiveContext);
  if (size === "xlarge" || size === "large") {
    return ["1/2", "1/2"];
  }
  if (size === "medium" || size === "small") {
    return ["1/2", "1/2"];
  }
  if (size === "xsmall") {
    return ["1/1", "1/1"];
  }

  // Add a default return statement here
  return ["default value 1", "default value 2"];
}

export function gridSearch() {
  const size = useContext(ResponsiveContext);
  if (["xxlarge", "xlarge", "large"].includes(size)) {
    return ["1/4", "3/4"];
  }
  if (size === "medium") {
    return ["1/3", "2/3"];
  }
  if (size === "xsmall" || size === "small") {
    return ["1/1", "1/1"];
  }
  // Add a default return statement here
  return ["1/2", "1/2"];
}

export function gridThreeColumns() {
  const size = useContext(ResponsiveContext);
  if (size === "xlarge" || size === "large") {
    return ["1/3", "1/3", "1/3"];
  }
  if (size === "medium" || size === "small") {
    return ["1/2", "1/2"];
  }
  if (size === "xsmall") {
    return ["1/1"];
  }
}

export function gridOneColumn() {
  const size = useContext(ResponsiveContext);
  if (size === "xlarge" || size === "large") {
    return ["1/1"];
  }
  if (size === "medium" || size === "small") {
    return ["1/1"];
  }
  if (size === "xsmall") {
    return ["1/1"];
  }

  return []; // Default return statement
}

export function gridFourColumns() {
  const size = useContext(ResponsiveContext);
  if (size === "xlarge" || size === "large") {
    return ["1/4", "1/4", "1/4", "1/4"];
  }
  if (size === "medium" || size === "small") {
    return ["1/3", "1/3", "1/3"];
  }
  if (size === "xsmall") {
    return ["1/1", "1/1", "1/1"];
  }
  return []; // Add this line to handle the case when `size` is not any of the specified values
}

export function gridSearchHome() {
  const size = useContext(ResponsiveContext);
  if (size === "xlarge" || size === "large") {
    return ["40%", "40%", "20%"];
  }
  if (size === "medium") {
    return ["40%", "40%", "20%"];
  }
  if (size === "xsmall" || size === "small") {
    return ["1/1", "1/1", "1/1"];
  }
  // Add a return statement for the case when size is "large"
  if (size === "large") {
    return ["your return value here"];
  }
  // Add a default return statement to handle any other cases
  return ["default return value here"];
}

export function gridHeader() {
  const size = useContext(ResponsiveContext);
  if (size === "xlarge" || size === "large") {
    return ["1/3", "1/3", "1/3"];
  }
  if (size === "medium") {
    return ["2/4", "1/4", "1/4"];
  }
  if (size === "small") {
    return ["1/2", "1/2"];
  }

  return []; // Default return value
}

export function gridSizeSide() {
  const size = useContext(ResponsiveContext);
  if (size === "xlarge" || size === "large") {
    return ["1/4", "3/4"];
  }
  if (size === "medium" || size === "small") {
    return ["1/4", "3/4"];
  }
  if (size === "xsmall") {
    return ["1/1", "1/1"];
  }

  // Add a return statement here
  return ["default value"];
}

export function gridResponsive() {
  const size = useContext(ResponsiveContext);

  if (size === "xlarge" || size === "large") {
    return ["1/4", "1/4", "1/4", "1/4"];
  }
  if (size === "medium") {
    return ["1/3", "1/3", "1/3"];
  }
  if (size === "small") {
    return ["1/2", "1/2"];
  }

  return []; // Add a default return statement here
}

export function gridResponsive2() {
  const size = useContext(ResponsiveContext);

  if (size === "xlarge" || size === "large") {
    return ["1/4", "1/4", "1/4", "1/4"];
  }
  if (size === "medium") {
    return ["1/3", "1/3", "1/3"];
  }
  if (size === "small") {
    return ["1/2", "1/2"];
  }

  // Add a default return statement here
  return [];
}

export function columnSize() {
  const size = useContext(ResponsiveContext);

  if (size === "xlarge" || size === "large") {
    return "large";
  }
  if (size === "medium") {
    return "medium";
  }
  if (size === "small") {
    return "medium";
  }

  // Add a default return statement here
  return "default value";
}

export const margin = {
  small: "10px",
  medium: "16px",
  large: "30px",
};

export const fontSize = {
  large: "19px",
  xlarge: "23px",
};

export const color = {
  main: "#1D3592",
  dark: "#022655",
  secondary: "#00B862",
  red: "#ff0000",
  yellow: "#ffee11",
  orange: "#fe5000",
  white: "#fdfdfd",
  black: "#151515",
  gray: "#f9f9f9",
  grayDark: "#999999",
  blacker: "#080808",
  accent: "#f1f1f1",
  border: "#dedede",
};

export const screenXs = 640;
export const screenSm = 900;
export const screenMd = 1200;
export const screenLg = 1480;
export const screenXL = 1600;

export function theme(colorMain, colorAccent) {
  return deepMerge(grommet, {
    global: {
      colors: {
        brand: colorMain,
        accent: colorAccent,
      },
      hoverIndicator: false,
      heading: {
        level: {
          1: {
            font: {
              family: "Bold",
            },
          },
        },
      },
      font: {
        family: "Regular",
        size: "18px",
        height: "20px",
      },
      breakpoints: {
        xsmall: {
          value: screenXs,
        },
        small: {
          value: screenSm,
        },
        medium: {
          value: screenMd,
        },
        large: {
          value: screenLg,
        },
        xlarge: {
          value: screenXL,
        },
      },
    },
  });
}

export function getThemeColors(siteDomain) {
  const domains = {
    "siilak.com": { domain: "siilak.com" },
    "localhost:8092": { domain: "siilak.com" },
    "robo.ee": { domain: "robo.ee" },
    "nuti.co": { domain: "nuti.co" },
  };

  const domain = domains[siteDomain];
  if (domain) {
    return { props: domain };
  }

  // Add a default return statement here
  return null;
}

export function getTheme(colorMain, colorAccent) {
  return theme(colorMain, colorAccent);
}

export function Visible(props: { width: number; children: any }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > props.width) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    };

    // check for initial visibility on component mount
    handleResize();

    window.addEventListener("resize", handleResize);

    // clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.width]);

  return visible ? props.children : null;
}

export function Hidden(props: { width: number; children?: any }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < props.width) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    };

    // check for initial visibility on component mount
    handleResize();

    window.addEventListener("resize", handleResize);

    // clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.width]);

  return visible ? <Box>{props.children}</Box> : null;
}

export function scrollToTop() {
  const scrollToOptions = {
    top: 0,
    behavior: "smooth",
  };
  window.scrollTo(scrollToOptions as ScrollToOptions);
}

export function getAllDatesBeforeToday() {
  const moment = require("moment");
  const startOfMonth = moment().startOf("month").toISOString();
  const yesterday = moment().subtract(1, "day").toISOString();
  return [[startOfMonth, yesterday]];
}
