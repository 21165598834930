import {
  Box,
  Button,
  DateInput,
  FileInput,
  Form,
  FormField,
  Notification,
  RangeInput,
  Select,
  Text,
  TextArea,
  TextInput,
} from "grommet";
import { FormNext } from "grommet-icons";
import { apiForm } from "helpers/Fetch";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { apiDomain, formatTimeEnd, formatTimeStart, getAllDatesBeforeToday } from "store/api";

import { FormRequestAutocomplete } from "./form/FormRequestAutocomplete";
import { FormSelect, FormSelectPeople } from "./form/FormSelect";
interface Props {
  site?: any;
  background?: string;
  formCollection: string;
  date_start?: string;
  date_end?: string;
  parent?: string;
  noGrid?: boolean;
  style?: any;
}

export interface Meta {
  id: string;
  field: string;
  width?: string;
  required?: boolean;
  interface?: {
    type: string;
    options: {
      choices: string[];
    };
  };
}

export interface Field {
  schema: any;
  meta: Meta;
  field: string;
  id: number;
  label: string;
  type: string;
  required: boolean;
  name: string;
  options: any;
  notes: string;
}

export interface FilterFormData {
  country?: string;
  currency?: string;
  yachtId?: string;
  product?: string;
  dateTo?: string;
  genoa?: string;
  kind?: string;
  mainsail?: string;
  maxCabins?: any;
  maxHeads?: any;
  maxLength?: any;
  maxPrice?: any;
  minCabins?: any;
  minHeads?: any;
  minLength?: any;
  minPrice?: string;
  model?: string;
  price?: string;
  service?: string;
  dateFrom?: string;
  flexibility?: any;
  sailingAreaId?: number;
  baseFromId?: number;
}

export const FormRequest = ({
  site,
  background,
  formCollection,
  parent,
  style,
  noGrid,
  date_start,
  date_end,
}: Props) => {
  const [showToast, setShowToast] = useState(false);
  const [formFields, setFormFields] = useState<Field[]>([]);
  const [formValues, setFormValues] = useState({});
  const [disabled, setDisabled] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Add your custom validation logic here
    const isValid = validateInput(name, value);

    if (isValid) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));

      // Update localStorage with the new value
      localStorage.setItem(name, value);
    } else {
      // Handle invalid input (e.g., show an error message)
      console.error(`Invalid input for ${name}: ${value}`);
    }
  };

  // Add your custom validation logic here
  const validateInput = (name, value) => {
    // Example: Validate if the value is not empty for required fields
    if (formFields.find((field) => field.meta.field === name)?.meta.required && !value.trim()) {
      return false;
    }

    // Add more validation rules based on your requirements

    return true; // Return true if the input is valid
  };

  const [formData, setFormData] = useState<FilterFormData>({
    currency: "",
    dateTo: "",
    genoa: "",
    kind: "",
    mainsail: "",
    maxCabins: "",
    maxHeads: "",
    maxLength: "",
    maxPrice: "",
    minCabins: "",
    minHeads: "",
    minLength: "",
    minPrice: "",
    yachtId: "",
    model: "",
    price: "",
    service: "",
    dateFrom: "",
    flexibility: "",
    sailingAreaId: undefined,
    baseFromId: undefined,
    product: "",
  });

  const today = new Date();
  const startDate =
    (typeof localStorage !== "undefined" && localStorage.getItem("date_start")) ||
    today.toISOString().split("T")[0];
  const endDate =
    (typeof localStorage !== "undefined" && localStorage.getItem("date_end")) ||
    moment(startDate).add(7, "days").format("YYYY-MM-DD");

  const [value, setValue] = useState({
    dateFrom: startDate,
    dateTo: endDate,
  });

  const [sendMessage, setSendMessage] = useState(true);

  const onChangeCalendar = (event) => {
    const [from, to] = event.value.map((dateString) => new Date(dateString));
    setValue({ dateFrom: from, dateTo: to });
    localStorage.setItem("date_start", from);
    localStorage.setItem("date_end", to);
    setSendMessage(true);
    setFormData({
      ...formData,
      dateFrom: moment(from).format("YYYY-MM-DD") + formatTimeStart(),
      dateTo: moment(to).format("YYYY-MM-DD") + formatTimeEnd(),
    });
  };

  const loadFormFields = async () => {
    try {
      const formFields = await apiForm(formCollection);
      setFormFields(formFields);
    } catch (error) {
      console.error("Failed to load form fields", error);
    }
  };

  useEffect(() => {
    loadFormFields().then();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendFormData();
  };

  const sendFormData = async () => {
    try {
      // Check if form value is not added by the user and take it from localStorage
      const finalFormValues = {};
      formFields.forEach(({ field }) => {
        const localStorageValue = localStorage.getItem(field);
        if (formValues[field]) {
          finalFormValues[field] = formValues[field];
        } else if (localStorageValue) {
          finalFormValues[field] = localStorageValue;
        }
      });
      // Add any other required fields to finalFormValues
      await fetch(`/api/form/${parent}/${formCollection}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...finalFormValues, status: "draft" }),
      });
      // Disable the form after a successful submission
      setDisabled(true);
      setShowToast(true);
    } catch (err) {
      console.error(err);
    }
  };

  function convert(str) {
    return str
      .split("_") // split string into array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize first letter of each word
      .join(" "); // join words with space
  }

  function setBorder(meta) {
    if (
      meta &&
      (meta.includes("presentation-links") ||
        meta.includes("presentation-notice") ||
        meta.includes("datetime") ||
        meta.includes("select") ||
        meta.includes("slider"))
    ) {
      return {
        border: false,
      };
    }
  }

  const formattedDateFrom = moment(date_start).format("DD. MMM yyyy | HH:mm");
  const formattedDateTo = moment(date_end).format("DD. MMM yyyy | HH:mm");

  return (
    <Box width="100%" background={background} style={style}>
      <Form onSubmit={handleSubmit}>
        <div style={{ float: "left", width: "100%" }}>
          {Array.isArray(formFields) &&
            formFields?.map((item, index) => (
              <div key={index}>
                <FormField
                  key={index}
                  margin="small"
                  contentProps={setBorder(item.type === "FieldtypePageTitleLanguage")}
                  label={
                    !["presentation-links", "presentation-notice", "file"].includes(item.type)
                      ? convert(item.field)
                      : ""
                  }
                  htmlFor={item.field}
                >
                  {item.type === "input" &&
                    !["booking_dates", "date_start", "date_end"].includes(item.name) && (
                      <TextInput
                        name={item.name}
                        type={item.type}
                        required={item.required}
                        placeholder={item.options?.placeholder}
                        onKeyUpCapture={(event) => handleInputChange(event)}
                        value={localStorage.getItem(item.name) ?? ""}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          localStorage.setItem(item.name, newValue);
                          handleInputChange(event);
                        }}
                      />
                    )}

                  {item.name === "date_start" && (
                    <TextInput
                      name={item.name}
                      type={item.type}
                      required={item.required}
                      placeholder={item.options?.placeholder}
                      onKeyUpCapture={(event) => handleInputChange(event)}
                      value={localStorage.getItem(item.name) ?? ""}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        localStorage.setItem(item.name, newValue);
                        handleInputChange(event);
                      }}
                    />
                  )}

                  {item.name === "date_end" && (
                    <TextInput
                      name={item.name}
                      type={item.type}
                      required={item.required}
                      placeholder={item.options?.placeholder}
                      onKeyUpCapture={(event) => handleInputChange(event)}
                      value={localStorage.getItem(item.name) ?? ""}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        localStorage.setItem(item.name, newValue);
                        handleInputChange(event);
                      }}
                    />
                  )}

                  {item.type === "select-dropdown" && item.name !== "how_many_people" && (
                    <FormSelect
                      name={item.name}
                      type={item.type}
                      formData={localStorage.getItem(item.name) ?? ""}
                      required={item.required}
                      selectData={item.options?.choices.map((item) => ({
                        label: item.text,
                        value: item.value,
                      }))}
                      options={(item) => ({
                        label: item.text,
                        value: item.value,
                      })}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        localStorage.setItem(item.name, newValue);
                        handleInputChange(event);
                      }}
                      isForm
                    />
                  )}
                  {item.type === "select-dropdown" && item.name === "how_many_people" && (
                    <FormSelectPeople
                      name={item.name}
                      type={item.type}
                      formData={localStorage.getItem(item.name) ?? ""}
                      required={item.required}
                      selectData={item.options?.choices.map((item) => ({
                        label: item.text,
                        value: item.value,
                      }))}
                      options={(item) => ({
                        label: item.text,
                        value: item.value,
                      })}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        localStorage.setItem(item.name, newValue);
                        handleInputChange(event);
                      }}
                      isForm
                    />
                  )}
                  {item.type === "select-radio" && (
                    <Select
                      name={item.name}
                      type={item.type}
                      required={item.required}
                      options={item.options.map((item) => ({
                        label: item.text,
                        value: item.value,
                      }))} // Closing parenthesis added here
                      onChange={handleInputChange}
                    />
                  )}
                  {item.type === "input-autocomplete-api" && (
                    <FormRequestAutocomplete name={item.name} type={item.type} meta={item} />
                  )}
                  {item.type === "input-multiline" && (
                    <TextArea
                      name={item.name}
                      placeholder={item.options?.placeholder}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        localStorage.setItem(item.name, newValue);
                        handleInputChange(event);
                      }}
                      resize="vertical"
                      style={{ minHeight: "100px" }}
                      value={localStorage.getItem(item.name) ?? ""}
                    />
                  )}
                  {item.type === "file" && (
                    <FileInput
                      name={item.name}
                      messages={{ dropPrompt: item.notes }}
                      onChange={handleInputChange}
                    />
                  )}

                  {item.type === "slider" && (
                    <Box>
                      {(() => {
                        const value = formValues[item.name] || "";
                        return (
                          <>
                            {/* <RangeInput
                            value={value}
                            min={item.options?.minValue}
                            max={item.options?.maxValue}
                            step={item.options?.stepInterval}
                            onChange={handleInputChange}
                            name={item.name}
                          /> */}
                            <Text>{value}</Text>
                          </>
                        );
                      })()}
                    </Box>
                  )}
                  {item.type === "datetime" && (
                    <Box style={{ borderRadius: "30px" }}>
                      <DateInput
                        value={[
                          value.dateFrom ? value.dateFrom.toString() : "",
                          value.dateTo ? value.dateTo.toString() : "",
                        ]}
                        calendarProps={{
                          firstDayOfWeek: 1,
                          daysOfWeek: true,
                          disabled: getAllDatesBeforeToday(),
                        }}
                        buttonProps={{
                          label: (
                            <Box justify="center" align="center" direction="row">
                              <Moment format="DD.MM.YYYY">{value.dateFrom ?? ""}</Moment>
                              <FormNext />
                              <Moment format="DD.MM.YYYY">{value.dateTo ?? ""}</Moment>
                            </Box>
                          ),
                        }}
                        onChange={onChangeCalendar}
                        style={{ zIndex: 100 }}
                      />
                      <Box align="center" margin="xsmall">
                        {value.dateFrom && value.dateTo
                          ? moment(value.dateTo).diff(moment(value.dateFrom), "days")
                          : 0}{" "}
                        days
                      </Box>
                    </Box>
                  )}
                </FormField>

                {item.name === "button" && (
                  <Button
                    key={item.id}
                    primary
                    color="accent"
                    size="large"
                    label={item.label}
                    onClick={handleSubmit}
                    disabled={disabled}
                  />
                )}

                {showToast && item.type === "presentation-notice" && (
                  <Notification title={item.type} toast />
                )}
              </div>
            ))}
        </div>
      </Form>
    </Box>
  );
};
